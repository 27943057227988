import { Box, Button, Modal } from "@mui/material";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { toggleCourier } from "../../../Components/Redux/Slice/Couriers/couriersSlice";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    height: 'auto',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 2,
    p: 4,
    borderRadius: 5,
};

const DeactivateCourier = ({ open, close, id, loading }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const form = useForm()
    const { register, handleSubmit, formState, reset } = form
    const { errors } = formState

    const closeModal = () => {
        close()
        reset()
    }

    const deactivateCourierSubmitHandler = async (data) => {
        let status = 'deactivate'

        let values = {
            id: id,
            ...data
        }
        dispatch(toggleCourier({ status, values })).then((e) => {
            if (e?.payload?.message === 'SUCCESS') {
                navigate('/dashboard/couriers')
            }
        })
    }

    return (
        <>
            <Modal
                open={open}
                onClose={closeModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <form onSubmit={handleSubmit(deactivateCourierSubmitHandler)}
                        className="[&>div>label]:font-semibold [&>div>label]:capitalize [&>div>label]:text-lg [&>div>label]:block [&>div>label]:text-mainColor [&>div>label]:mb-4
                                [&>div>textarea]:w-full [&>div>textarea]:shadow-sm [&>div>textarea]:resize-none [&>div>textarea]:h-[120px] [&>div>textarea]:border [&>div>textarea]:rounded-md [&>div>textarea]:p-2 [&>div>textarea]:text-mainColor"
                    >
                        <div>
                            <label>{t('couriers.courier_details.deactivateReason')}</label>

                            <textarea
                                className="placeholder:capitalize"
                                // placeholder={t('couriers.courier_details.deactivateReason')}
                                {...register('deactivationReason', {
                                    required: {
                                        value: true,
                                        message: t('common.required')
                                    },
                                    pattern: {
                                        value: /^[a-zA-Z0-9\s]+$/,
                                        message: localStorage.getItem('lang') === 'ar' ? 'مسموح بالاحرف الانجليزيه والارقام و المسافات فقط' : 'Only English letters, numbers, and spaces are allowed'
                                    },
                                })}
                            >

                            </textarea>

                            {errors?.deactivationReason?.message ? <p className="capitalize text-sm text-danger">{errors?.deactivationReason?.message}</p> : null}
                        </div>

                        <div className="mt-4">
                            <label>{t('couriers.courier_details.deactivateReasonAr')}</label>

                            <textarea
                                className="placeholder:capitalize"
                                // placeholder={t('couriers.courier_details.deactivateReasonAr')}
                                {...register('deactivationReasonAr', {
                                    required: {
                                        value: true,
                                        message: t('common.required')
                                    },
                                    pattern: {
                                        value: /^[\u0621-\u064A\u0660-\u0669\u0030-\u0039\s]+$/,
                                        message: localStorage.getItem('lang') === 'ar' ? 'مسموح بالاحرف العربية والارقام ' : 'Only Arabic letters, numbers, spaces, are allowed'
                                    },
                                })}
                            >

                            </textarea>

                            {errors?.deactivationReasonAr?.message ? <p className="capitalize text-sm text-danger">{errors?.deactivationReasonAr?.message}</p> : null}
                        </div>

                        <div className="mt-6 flex items-center justify-around [&>button]:capitalize [&>button]:font-semibold [&>button]:px-6">
                            <Button onClick={closeModal} className="disabled:bg-gray-400 disabled:opacity-50 disabled:cursor-not-allowed justify-center  bg-transparent text-mainRed border border-solid border-mainRed">{t('common.cancel')}</Button>
                            <Button type="submit" disabled={loading} className="disabled:bg-gray-400 disabled:opacity-50 disabled:cursor-not-allowed justify-center  bg-mainColor text-white">{t('common.confirm')}</Button>
                        </div>
                    </form>
                </Box>
            </Modal>
        </>
    );
}

export default DeactivateCourier;