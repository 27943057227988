import { Box, Button, Modal } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import BreadCrumb from "../../../Components/BreadCrumb/breadCrumb";
import { getCourierById, rejectCourier, toggleCourier } from "../../../Components/Redux/Slice/Couriers/couriersSlice";
import Overlay from "../../../Components/Common/overlay";
import { HiOutlineUser } from "react-icons/hi";
import { BsGlobe, BsTelephone } from "react-icons/bs";
import { FaRegIdCard } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import PayoutModal from "./changePayoutModal";
import DeactivateCourier from "./deactivateCourier";


const CourierDetails = () => {
    const { t, i18n } = useTranslation()
    const singleCourier = useSelector(state => state?.couriers)
    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()

    // console.log(singleCourier?.singleCourier?.data);
    const id = location?.state?.id

    // console.log(location?.state?.data);

    useEffect(() => {

        dispatch(getCourierById(id))

        // console.log(location?.state?.id);
    }, [dispatch, location.pathname, id, location, location?.state?.id])

    const data = singleCourier?.singleCourier?.data

    useEffect(() => {
        // console.log(singleCourier);
    }, [singleCourier])

    const style = {
        position: 'absolute',
        top: '45%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        minWidth: '50%',
        height: '70%',
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        boxShadow: 2,
        p: 2,
        borderRadius: 5,
    };

    const rejectStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '60%',
        height: 'auto',
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        boxShadow: 2,
        p: 4,
        borderRadius: 5,
    };

    const [modalImg, setmodalImg] = useState('');
    const [open, setOpen] = useState(false);

    const handleOpen = (e) => {
        setOpen(true)
        setmodalImg(e?.target.src)

    };
    const handleClose = () => setOpen(false);

    const paths = [
        { path: '/dashboard/couriers', name: 'Couriers', nameAr: "السائقين" },
        { path: '', name: 'courier details', nameAr: "بيانات السائق" },
    ]

    const toggleCourierHandler = async (status) => {
        dispatch(toggleCourier({ status, id })).then((e) => {
            if (e?.payload?.message === 'SUCCESS') {
                navigate('/dashboard/couriers')
            }
        })
    }

    const form = useForm()
    const { register, handleSubmit, formState } = form
    const { errors } = formState

    const rejectCourierSubmitHandler = async (data) => {
        let values = {
            id: id,
            status: 'reject',
            ...data
        }
        dispatch(rejectCourier(values)).then((e) => {
            if (e?.payload?.message === 'SUCCESS') {
                navigate('/dashboard/couriers')
            }
        })
    }

    const [openRejectModal, setOpenRejectModal] = useState(false);

    const handleOpenRejectModal = (e) => {
        setOpenRejectModal(true)

    };
    const handleCloseRejectModal = () => setOpenRejectModal(false);

    const auth = useSelector(state => state.auth)

    const cancelBtnHandler = () => {
        navigate(-1)
    }

    // console.log(singleCourier?.singleCourier?.data);

    // payout setting modal

    const [openPayout, setopenPayout] = useState(false)

    const handleOpenPayout = () => {
        setopenPayout(true)
    }

    const handleClosePayout = () => {
        setopenPayout(false)
    }


    // deactivate courier modal

    const [openDeactivate, setopenDeactivate] = useState(false);

    const handleOpenDeactivate = (e) => {
        setopenDeactivate(true)

    };
    const handleCloseDeactivate = () => setopenDeactivate(false);

    return (

        // <article className="courier-wrapper mt-6">
        //     <div className=" relative flex flex-wrap md:flex-nowrap  justify-between gap-4 items-start [&>section]:basis-full [&>section]:rounded-md mb-32">
        //         <section className="profile-left-section bg-white p-5">
        //             <div className="profile-title text-[#333333] font-semibold capitalize text-xl">
        //                 <h2>Courier Details</h2>
        //             </div>

        //             <div><BreadCrumb paths={paths} seperator='/' /></div>

        //             <div className="courierDetails-wrapper mt-5">

        //                 <div className="courier-preview-wrappe w-full h-[250px] relative">

        //                     <div className="restaurant-profile-image-preview  w-[500px] h-[280px] bg-[rgba(0,0,0,.3)] left-6 rounded-lg m-auto">
        //                         <div className="preview-wrapper [&>img]:cursor-pointer shadow-md relative rounded-lg z-30 w-full h-full flex justify-center">

        //                             {singleCourier?.singleCourier?.data?.profileImage && <img src={singleCourier?.singleCourier?.data?.profileImage} loading="lazy" onClick={(e)=>handleOpen(e)} alt="Courier" className="w-full h-full rounded-lg object-cover" />}
        //                         </div>
        //                     </div>

        //                 </div>

        //                 <div className="form-input-wrapper mt-24 grid grid-cols-1 md:grid-cols-2 gap-8 [&>div>label]:capitalize [&>div>label]:text-[#333] [&>div>label]:text-sm [&>div>label]:block [&>div>label]:mb-2 [&>div>label]:font-semibold [&>div>input]:bg-[#f3f6f9] [&>div>input]:w-full [&>div>input]:p-3 [&>div>input]:focus:outline-slate-400 [&>div>input]:rounded-md [&>div>input]:text-mainColor [&>div>input]:mb-1">
        //                     <div className="col-span-2 pi-title">
        //                         <h2 className="font-semibold text-xl text-mainColor border-b pb-2">Personal Information</h2>
        //                     </div>
        //                     {/* Courier Information Fields */}

        //                     <div>
        //                         <label>First Name</label>
        //                         <input 
        //                             type="text" 
        //                             id="fName"
        //                             value={singleCourier?.singleCourier?.data?.firstName}
        //                             readOnly
        //                         />
        //                     </div>

        //                     <div>
        //                         <label>Last Name</label>
        //                         <input 
        //                             type="text" 
        //                             id="lName"
        //                             value={singleCourier?.singleCourier?.data?.lastName}
        //                             readOnly
        //                         />
        //                     </div>

        //                     <div>
        //                         <label>phone number</label>
        //                         <input 
        //                             type="text" 
        //                             id="phoneNumber"
        //                             value={singleCourier?.singleCourier?.data?.mobileNumber}
        //                             readOnly
        //                         />

        //                     </div>

        //                     <div>
        //                         <label>Citizinship</label>
        //                         <input 
        //                             type="text" 
        //                             id="citizinship"
        //                             value={singleCourier?.singleCourier?.data?.citizenship}
        //                             readOnly
        //                         />
        //                     </div>

        //                     <div className="col-span-2">
        //                         <label>National ID</label>
        //                         <input type="text" id="nID"
        //                             value={singleCourier?.singleCourier?.data?.nationalId}
        //                             readOnly
        //                         />
        //                     </div>

        //                     <div className="col-span-2">
        //                         <label>National ID Photo</label>
        //                         <div className="w-full flex justify-around gap-x-4 mt-6 [&>div]:w-[350px] [&>div]:h-[180px] [&>div]:p-3 [&>div]:rounded-md [&>div]:border">

        //                             <div className="national-id-photo">

        //                                 <div className="Courier-image-preview w-full h-full bg-[rgba(0,0,0,.3)] left-6 rounded-lg m-auto">
        //                                     <div className="preview-wrapper [&>img]:cursor-pointer shadow-md rounded-lg w-full h-full flex justify-center">

        //                                         {singleCourier?.singleCourier?.data?.nationalIdFrontPhoto && <img src={singleCourier?.singleCourier?.data?.nationalIdFrontPhoto} loading="lazy" onClick={(e)=>handleOpen(e)} alt="NID-Front" className="w-full h-full rounded-lg object-cover" />}
        //                                     </div>
        //                                 </div>

        //                             </div>

        //                             <div className="national-id-photo">

        //                                 <div className="restaurant-profile-image-preview w-full h-full bg-[rgba(0,0,0,.3)] left-6 rounded-lg m-auto">
        //                                     <div className="preview-wrapper [&>img]:cursor-pointer shadow-md rounded-lg w-full h-full flex justify-center">

        //                                         {singleCourier?.singleCourier?.data?.nationalIdBackPhoto && <img src={singleCourier?.singleCourier?.data?.nationalIdBackPhoto} loading="lazy" onClick={(e)=>handleOpen(e)} alt="NID-Back" className="w-full h-full rounded-lg object-cover" />}
        //                                     </div>
        //                                 </div>

        //                             </div>
        //                         </div>

        //                         <div className="w-full flex justify-around gap-x-4 text-[#CED6E0] mt-1 text-xs [&>p]:w-[350px] [&>p]:m-0 capitalize">
        //                             <p>front</p>
        //                             <p>back</p>
        //                         </div>
        //                     </div>

        //                     <div className="col-span-2 ri-title mt-4">
        //                         <h2 className="font-semibold text-xl text-mainColor border-b pb-2">Driving Information</h2>
        //                     </div>

        //                     <div className="col-span-2">
        //                         <label>Drive License Number</label>
        //                         <input type="text" id="drivingLicenseNumber"
        //                             value={singleCourier?.singleCourier?.data?.drivingLicenseNumber}
        //                             readOnly
        //                         />

        //                     </div>

        //                     <div className="col-span-2">
        //                         <label>Drive License Photo</label>

        //                         <div className="w-full flex justify-around gap-x-4 mt-6 [&>div]:w-[350px] [&>div]:h-[180px] [&>div]:p-3 [&>div]:rounded-md [&>div]:border">

        //                             <div className="national-id-photo">

        //                                 <div className="restaurant-profile-image-preview w-full h-full bg-[rgba(0,0,0,.3)] left-6 rounded-lg m-auto">
        //                                     <div className="preview-wrapper [&>img]:cursor-pointer shadow-md rounded-lg w-full h-full flex justify-center">

        //                                         {singleCourier?.singleCourier?.data?.drivingLicenseFrontPhoto && <img src={singleCourier?.singleCourier?.data?.drivingLicenseFrontPhoto} loading="lazy" onClick={(e)=>handleOpen(e)} alt="Licence" className="w-full h-full rounded-lg object-cover" />}
        //                                     </div>
        //                                 </div>

        //                             </div>

        //                             <div className="national-id-photo">

        //                                 <div className="restaurant-profile-image-preview w-full h-full bg-[rgba(0,0,0,.3)] left-6 rounded-lg m-auto">
        //                                     <div className="preview-wrapper [&>img]:cursor-pointer shadow-md rounded-lg w-full h-full flex justify-center">

        //                                         {singleCourier?.singleCourier?.data?.drivingLicenseBackPhoto && <img src={singleCourier?.singleCourier?.data?.drivingLicenseBackPhoto} loading="lazy" onClick={(e)=>handleOpen(e)} alt="Licence" className="w-full h-full rounded-lg object-cover" />}
        //                                     </div>
        //                                 </div>

        //                             </div>
        //                         </div>

        //                         <div className="w-full flex justify-around gap-x-4 text-[#CED6E0] mt-1 text-xs [&>p]:w-[350px] [&>p]:m-0 capitalize">
        //                             <p>front</p>
        //                             <p>back</p>
        //                         </div>
        //                     </div>

        //                     <div>
        //                         <label>Vechile Type</label>
        //                         <input type="text" id="vechileType"
        //                             value={singleCourier?.singleCourier?.data?.vehicleType}
        //                             readOnly
        //                         />
        //                     </div>

        //                     <div>
        //                         <label>Car Model</label>
        //                         <input type="text" id="carModel"                                    
        //                             value={singleCourier?.singleCourier?.data?.vehicleModel}
        //                             readOnly
        //                         />
        //                     </div>

        //                     <div>
        //                         <label>Vehicle Plate letters</label>
        //                         <input type="text" id="plateLetters"
        //                             value={singleCourier?.singleCourier?.data?.plateLetters}
        //                             readOnly
        //                         />
        //                     </div>

        //                     <div>
        //                         <label>Vehicle Plate Number</label>
        //                         <input type="text" id="plateNumbers"
        //                             value={singleCourier?.singleCourier?.data?.plateNumbers}
        //                             readOnly
        //                         />
        //                     </div>

        //                     <div className="col-span-2">
        //                         <label>Car year of production</label>
        //                         <input type="text" id="productionYear"
        //                             value={singleCourier?.singleCourier?.data?.yearOfProduction}
        //                             readOnly
        //                         />
        //                     </div>

        //                     <div className="col-span-2">
        //                         <label>Car Photo</label>

        //                         <div className="w-full flex justify-around gap-x-4 mt-6 [&>div]:w-[350px] [&>div]:h-[180px] [&>div]:p-3 [&>div]:rounded-md [&>div]:border">

        //                             <div className="national-id-photo">

        //                                 <div className="restaurant-profile-image-preview w-full h-full bg-[rgba(0,0,0,.3)] left-6 rounded-lg m-auto">
        //                                     <div className="preview-wrapper [&>img]:cursor-pointer shadow-md rounded-lg w-full h-full flex justify-center">

        //                                         {singleCourier?.singleCourier?.data?.vehicleFirstPhoto && <img src={singleCourier?.singleCourier?.data?.vehicleFirstPhoto} loading="lazy" onClick={(e)=>handleOpen(e)} alt="Car-Img" className="w-full h-full rounded-lg object-cover" />}
        //                                     </div>
        //                                 </div>

        //                             </div>

        //                             <div className="national-id-photo">

        //                                 <div className="restaurant-profile-image-preview w-full h-full bg-[rgba(0,0,0,.3)] left-6 rounded-lg m-auto">
        //                                     <div className="preview-wrapper [&>img]:cursor-pointer shadow-md rounded-lg w-full h-full flex justify-center">

        //                                         {singleCourier?.singleCourier?.data?.vehicleSecondPhoto && <img src={singleCourier?.singleCourier?.data?.vehicleSecondPhoto} loading="lazy" alt="Car-Img" className="w-full h-full rounded-lg object-cover" />}
        //                                     </div>
        //                                 </div>

        //                             </div>
        //                         </div>

        //                         <div className="w-full flex justify-around gap-x-4 text-[#CED6E0] mt-1 text-xs [&>p]:w-[350px] [&>p]:m-0 capitalize">
        //                             <p>front</p>
        //                             <p>back</p>
        //                         </div>
        //                     </div>

        //                     <div className="col-span-2">
        //                         <label>Car Registration Photo</label>

        //                         <div className="w-full flex justify-around gap-x-4 mt-6 [&>div]:w-[350px] [&>div]:h-[180px] [&>div]:p-3 [&>div]:rounded-md [&>div]:border">

        //                             <div className="national-id-photo">

        //                                 <div className="restaurant-profile-image-preview w-full h-full bg-[rgba(0,0,0,.3)] left-6 rounded-lg m-auto">
        //                                     <div className="preview-wrapper [&>img]:cursor-pointer shadow-md rounded-lg w-full h-full flex justify-center">

        //                                         {singleCourier?.singleCourier?.data?.carRegisterationFrontPhoto && <img src={singleCourier?.singleCourier?.data?.carRegisterationFrontPhoto} loading='lazy' onClick={(e)=>handleOpen(e)} alt="Car Registeration Front" className="w-full h-full rounded-lg object-cover" />}
        //                                     </div>
        //                                 </div>

        //                             </div>

        //                             <div className="national-id-photo">

        //                                 <div className="restaurant-profile-image-preview w-full h-full bg-[rgba(0,0,0,.3)] left-6 rounded-lg m-auto">
        //                                     <div className="preview-wrapper [&>img]:cursor-pointer shadow-md rounded-lg w-full h-full flex justify-center">

        //                                         {singleCourier?.singleCourier?.data?.carRegisterationBackPhoto && <img src={singleCourier?.singleCourier?.data?.carRegisterationBackPhoto} loading="lazy" alt="Car Registeration Back" className="w-full h-full rounded-lg object-cover" />}
        //                                     </div>
        //                                 </div>

        //                             </div>
        //                         </div>

        //                         <div className="w-full flex justify-around gap-x-4 text-[#CED6E0] mt-1 text-xs [&>p]:w-[350px] [&>p]:m-0 capitalize">
        //                             <p>front</p>
        //                             <p>back</p>
        //                         </div>
        //                     </div>

        //                     <div>
        //                         <label htmlFor="workingCity">Working Region</label>
        //                         <input type="text" id="workingCity"
        //                             value={singleCourier?.singleCourier?.data?.workingRegion}
        //                             readOnly
        //                         />
        //                     </div>

        //                     <div>
        //                         <label htmlFor="workingCity">Working City</label>
        //                         <input type="text" id="workingCity"
        //                             value={singleCourier?.singleCourier?.data?.workingCity}
        //                             readOnly
        //                         />
        //                     </div>

        //                     <div className="col-span-2">
        //                         <label htmlFor="address">living Address</label>
        //                         <input type="text" id="address"
        //                             value={singleCourier?.singleCourier?.data?.address}
        //                             readOnly
        //                         />
        //                     </div>

        //                 </div>

        //                 {auth?.role === 'SUPER_ADMIN' ? 

        //                     <div className="courier-submit-btn-wrapper mt-10 flex gap-x-8 justify-end [&>button]:px-5 [&>button]:py-2">
        //                         {location?.state?.type === 'request' ?
        //                             <>
        //                                 <Button onClick={()=>toggleCourierHandler('reject')} disabled={singleCourier?.loading} className="disabled:bg-gray-400 disabled:opacity-50 disabled:cursor-not-allowed justify-center  p-0 bg-mainRed text-white text-sm font-semibold rounded-md">Decline</Button>
        //                                 <Button onClick={()=>toggleCourierHandler('accept')} disabled={singleCourier?.loading} className="disabled:bg-gray-400 disabled:opacity-50 disabled:cursor-not-allowed justify-center  p-0 bg-mainGreen text-white text-sm font-semibold rounded-md">Approve</Button>
        //                             </>
        //                         : location?.state?.type === 'rejected' ? 
        //                             null
        //                         :
        //                         location?.state?.type === 'status' ?
        //                             <>
        //                                 {singleCourier?.singleCourier?.data?.isActive === 0 ?
        //                                     <Button onClick={()=>toggleCourierHandler('activate')} disabled={singleCourier?.loading} className={`disabled:bg-gray-400 disabled:opacity-50 disabled:cursor-not-allowed justify-center  p-0 bg-mainGreen text-white text-sm font-semibold rounded-md`}>Activate</Button>
        //                                     :
        //                                     <Button onClick={()=>toggleCourierHandler('deactivate')} disabled={singleCourier?.loading} className={`disabled:bg-gray-400 disabled:opacity-50 disabled:cursor-not-allowed justify-center  p-0 bg-mainRed text-white text-sm font-semibold rounded-md`}>Deactivate</Button>
        //                                 }


        //                             </>

        //                         : null
        //                     }
        //                     </div>

        //                     : null
        //                 }


        //             </div>
        //         </section>
        //     </div>

        //     <Modal
        //         open={open}
        //         onClose={handleClose}
        //         aria-labelledby="modal-modal-title"
        //         aria-describedby="modal-modal-description"
        //     >
        //         <Box sx={style}>
        //             <img src={modalImg} loading="lazy" alt={`imgae-${modalImg}`} className="w-full h-full" />
        //         </Box>
        //     </Modal>

        //     {singleCourier?.loading ? <Overlay /> : null}

        // </article>

        <article className="courier-wrapper py-3">
            <div className="courier-title text-[#333333] font-bold capitalize text-3xl mb-3 font-playfair">
                <h2 className="font-playfair">{t('couriers.courier_details.title')}</h2>
            </div>
            <div><BreadCrumb paths={paths} seperator='/' /></div>


            <section className="courier-details-wrapper-section flex flex-wrap justify-between items-start gap-2">
                <div className="courier-info-wrapper  basis-[49%]">
                    <div className="courier-personal-info-wrapper bg-white p-4 rounded-md">
                        <div className="flex items-center justify-between gap-x-3">
                            <h2 className="capitalize text-lg text-[#333] font-semibold">{t('couriers.courier_details.personal_info')}</h2>

                            <span className={`capitalize bg-opacity-25 text-sm p-1 px-2 rounded-sm ${singleCourier?.singleCourier?.data?.status === 'REJECTED' ? 'bg-mainRed text-mainRed' : singleCourier?.singleCourier?.data?.status === 'APPROVED' ? 'bg-mainGreen text-mainGreen' : singleCourier?.singleCourier?.data?.status === 'PENDING_FOR_APPROVAL' ? 'bg-mainYellow text-mainYellow' : null}`}>
                                {singleCourier?.singleCourier?.data?.status?.toLowerCase()?.replace(/_/g, ' ')}
                            </span>
                        </div>

                        <div className="flex justify-between items-start gap-4 mt-4">
                            {singleCourier?.singleCourier?.data?.profileImage ? <img src={singleCourier?.singleCourier?.data?.profileImage} loading="lazy" onClick={(e) => handleOpen(e)} alt="Courier" className="w-[150px] h-[150px] rounded-[50%] object-cover cursor-pointer" /> : <span className="w-[150px] h-[150px] rounded-[50%] bg-gray-500"></span>}

                            <div className="personal-info-details grid grid-cols-2 gap-y-3 justify-between grow">

                                <div className="flex items-center mb-2 gap-x-2">
                                    <div >
                                        <span className="w-8 h-8 rounded-[50%] bg-mainYellow text-center flex items-center"><HiOutlineUser className="m-auto text-white text-lg" /></span>
                                    </div>

                                    <div className="capitalize">
                                        <p className="text-[#B5B5C3] text-[12px]">{t('users.form.first_name')}</p>
                                        <p className="text-[#464E5F] text-sm font-semibold">{singleCourier?.singleCourier?.data?.firstName ? singleCourier?.singleCourier?.data?.firstName : 'name not found'}</p>
                                    </div>
                                </div>

                                <div className="flex items-center mb-2 gap-x-2">
                                    <div >
                                        <span className="w-8 h-8 rounded-[50%] bg-mainYellow text-center flex items-center"><HiOutlineUser className="m-auto text-white text-lg" /></span>
                                    </div>

                                    <div className="capitalize">
                                        <p className="text-[#B5B5C3] text-[12px]">{t('users.form.last_name')}</p>
                                        <p className="text-[#464E5F] text-sm font-semibold">{singleCourier?.singleCourier?.data?.lastName ? singleCourier?.singleCourier?.data?.lastName : 'name not found'}</p>
                                    </div>
                                </div>

                                <div className="flex items-center mb-2 gap-x-2">
                                    <div >
                                        <span className="w-8 h-8 rounded-[50%] bg-mainYellow text-center flex items-center"><BsTelephone className="m-auto text-white text-lg" /></span>
                                    </div>

                                    <div className="capitalize">
                                        <p className="text-[#B5B5C3] text-[12px]">{t('couriers.courier_details.telephone')}</p>
                                        <p className="text-[#464E5F] text-sm font-semibold">{singleCourier?.singleCourier?.data?.mobileNumber ? singleCourier?.singleCourier?.data?.mobileNumber : 'telephone not found'}</p>
                                    </div>
                                </div>

                                <div className="flex items-center mb-2 gap-x-2">
                                    <div >
                                        <span className="w-8 h-8 rounded-[50%] bg-mainYellow text-center flex items-center"><BsGlobe className="m-auto text-white text-lg" /></span>
                                    </div>

                                    <div className="capitalize">
                                        <p className="text-[#B5B5C3] text-[12px]">{t('couriers.courier_details.citizenship')}</p>
                                        <p className="text-[#464E5F] text-sm font-semibold">{singleCourier?.singleCourier?.data?.citizenship ? singleCourier?.singleCourier?.data?.citizenship : 'citizenship not found'}</p>
                                    </div>
                                </div>

                                <div className="flex items-center mb-2 gap-x-2">
                                    <div >
                                        <span className="w-8 h-8 rounded-[50%] bg-mainYellow text-center flex items-center"><FaRegIdCard className="m-auto text-white text-lg" /></span>
                                    </div>

                                    <div className="capitalize">
                                        <p className="text-[#B5B5C3] text-[12px]">{singleCourier?.singleCourier?.data?.citizenship !== 'Saudi Arabia' ? 'Iqama ID' : 'national ID number'}</p>
                                        <p className="text-[#464E5F] text-sm font-semibold">{singleCourier?.singleCourier?.data?.nationalId ? singleCourier?.singleCourier?.data?.nationalId : 'national ID not found'}</p>
                                    </div>
                                </div>

                                <div className="col-span-2 capitalize font-semibold text-sm text-[#333]">
                                    <p>{singleCourier?.singleCourier?.data?.citizenship !== 'Saudi Arabia' ? localStorage.getItem('lang') === 'ar' ? 'رقم الاقامة' : 'Iqama ID' : localStorage.getItem('lang') === 'ar' ? 'الرقم القومى' : 'national ID'} {localStorage.getItem('lang') === 'ar' ? '(الوجه والظهر)' : '(front & back)'}</p>

                                    <div className="nationalid-images-wrapper flex justify-between gap-3 items-center [&>div]:basis-[49%] [&>div]:w-full [&>div]:h-[150px] mt-3">
                                        <div className="national-id-photo">
                                            {singleCourier?.singleCourier?.data?.nationalIdFrontPhoto ? <img src={singleCourier?.singleCourier?.data?.nationalIdFrontPhoto} loading="lazy" onClick={(e) => handleOpen(e)} alt="NID-Front" className="w-full h-full rounded-lg object-cover cursor-pointer" /> : <span className="block w-full h-full rounded-lg bg-[rgba(0,0,0,.2)]"></span>}
                                        </div>

                                        <div className="national-id-photo">
                                            {singleCourier?.singleCourier?.data?.nationalIdBackPhoto ? <img src={singleCourier?.singleCourier?.data?.nationalIdBackPhoto} loading="lazy" onClick={(e) => handleOpen(e)} alt="NID-Back" className="w-full h-full rounded-lg object-cover cursor-pointer" /> : <span className="block w-full h-full rounded-lg bg-[rgba(0,0,0,.2)]"></span>}
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="mt-6 p-4 bg-white rounded-md">
                        <div className="flex items-center justify-between mb-6">
                            <h2 className="font-bold text-lg text-[#333]">{t('couriers.courier_details.title')}</h2>
                            <Button onClick={handleOpenPayout} className="min-w-[120px] bg-mainColor text-white capitalize font-Ubuntu">{t('couriers.courier_details.editPayoutBtn')}</Button>
                        </div>

                        <div className="courierdetails-info-wrapper flex gap-5 capitalize">
                            <section className="left-section-wrapper [&>div]:capitalize] [&>div]:mb-3 [&>div>label]:mb-2 [&>div>label]:text-[#333] [&>div>label]:font-semibold [&>div>p]:text-sm ">
                                <div>
                                    <label>{t('couriers.courier_details.courierType')}</label>
                                    <p className="text-[#c9c9c9]">{singleCourier?.singleCourier?.data?.isFreelancer ? t('couriers.freelancer') : <span>{t('couriers.worksFor')} {localStorage.getItem('lang') === 'ar' ? singleCourier?.singleCourier?.data?.deliveryContractor?.companyNameAr : singleCourier?.singleCourier?.data?.deliveryContractor?.companyName}</span>}</p>
                                </div>

                                <div className="payout-status">
                                    <label>{t('couriers.courier_details.payoutStatus')}</label>
                                    <p className={`w-fit py-1 px-2 rounded-md text-sm font-semibold bg-opacity-25 ${singleCourier?.singleCourier?.data?.isPayoutInherited === true ? 'text-mainGreen bg-mainGreen' : singleCourier?.singleCourier?.data?.isPayoutInherited === false ? 'text-mainYellow bg-mainYellow' : null}`}>{singleCourier?.singleCourier?.data?.isPayoutInherited ? t('common.default') : t('common.changed')}</p>
                                </div>
                            </section>

                            <section className="left-section-wrapper mb-4 [&>div]:capitalize] [&>div]:mb-3 [&>div>label]:mb-2 [&>div>label]:text-[#333] [&>div>label]:font-semibold [&>div>p]:text-sm ">
                                <div>
                                    <label>{t('deliveryContractors.columnsName.payoutMethod')}</label>
                                    <p className="text-[#c9c9c9]">{singleCourier?.singleCourier?.data?.payoutAmountType ? singleCourier?.singleCourier?.data?.payoutAmountType?.toLowerCase()?.replace(/_/g, ' ') : t('common.notFound')}</p>
                                </div>

                                <div>
                                    <label>{t('couriers.courierStatus')}</label>
                                    <p className={`w-fit text-sm font-semibold ${singleCourier?.singleCourier?.data?.status === 'APPROVED' ? 'text-mainGreen' : singleCourier?.singleCourier?.data?.status === 'PENDING_FOR_APPROVAL' ? 'text-mainYellow' : singleCourier?.singleCourier?.data?.status === 'REJECTED' ? 'text-mainRed' : null}`}>{singleCourier?.singleCourier?.data?.status?.toLowerCase()?.replace(/_/g, ' ')}</p>
                                </div>
                            </section>
                        </div>

                        {singleCourier?.singleCourier?.data?.status === 'PENDING_FOR_APPROVAL' ?
                            <div>
                                <label className="font-semibold text-[#333] capitalize mb-2">
                                    {t('couriers.statusReason')}
                                </label>

                                <p className="text-sm text-[#c9c9c9] capitalize">{localStorage?.getItem('lang') === 'ar' ? singleCourier?.singleCourier?.data?.reviewReasonAr : singleCourier?.singleCourier?.data?.reviewReason}</p>
                            </div>

                            :

                            singleCourier?.singleCourier?.data?.status === 'REJECTED' ?

                                <div>
                                    <label className="font-semibold text-[#333] capitalize mb-2">
                                        {t('couriers.courier_details.rejectReason')}
                                    </label>

                                    <p className="text-sm text-mainRed capitalize">{singleCourier?.singleCourier?.data?.rejectionReason}</p>
                                </div>

                                :

                                null
                        }


                    </div>
                </div>

                <div className="courier-driving-info bg-white p-4 rounded-md basis-[49%]">
                    <h2 className="capitalize text-[19px] text-[#333] font-semibold">{t('couriers.courier_details.driving_info')}</h2>

                    <div className="driving-info-details-wrapper capitalize [&>div>label]:text-[16px] [&>div>label]:text-[#333] [&>div>label]:font-semibold [&>div>label]:mb-2 [&>div>p]:text-sm mt-4">
                        <div className="w-full [&>p]:text-[#999999]">
                            <label>{t('couriers.courier_details.driving_license')}</label>
                            <p>{singleCourier?.singleCourier?.data?.drivingLicenseNumber ? singleCourier?.singleCourier?.data?.drivingLicenseNumber : 'none'}</p>
                        </div>

                        <div className="w-full capitalize font-semibold text-sm mt-4 [&>p]:text-[#333]">
                            <p>{t('couriers.courier_details.driving_license_photo')} {localStorage.getItem('lang') === 'ar' ? '(الوجه والظهر)' : '(front & back)'}</p>

                            <div className="carLiecense-images-wrapper flex justify-between gap-3 items-center [&>div]:basis-[49%] [&>div]:w-full [&>div]:h-[150px] mt-3">
                                <div className="carLiecense-photo">
                                    {singleCourier?.singleCourier?.data?.drivingLicenseFrontPhoto ? <img src={singleCourier?.singleCourier?.data?.drivingLicenseFrontPhoto} loading="lazy" onClick={(e) => handleOpen(e)} alt="NID-Front" className="w-full h-full rounded-lg object-cover cursor-pointer" /> : <span className="block w-full h-full rounded-lg bg-[rgba(0,0,0,.2)]"></span>}
                                </div>

                                <div className="carLiecense-photo">
                                    {singleCourier?.singleCourier?.data?.drivingLicenseBackPhoto ? <img src={singleCourier?.singleCourier?.data?.drivingLicenseBackPhoto} loading="lazy" onClick={(e) => handleOpen(e)} alt="NID-Back" className="w-full h-full rounded-lg object-cover cursor-pointer" /> : <span className="block w-full h-full rounded-lg bg-[rgba(0,0,0,.2)]"></span>}
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-wrap items-start gap-4 mt-4 [&>div]:basis-[40%] [&>div>label]:text-sm [&>div>label]:text-[#333] [&>div>label]:font-semibold [&>div>label]:mb-2 [&>div>p]:text-sm [&>div>p]:text-[#999999]">
                            <div>
                                <label>{t('couriers.courier_details.vehicle_type')}</label>
                                <p>{singleCourier?.singleCourier?.data?.vehicleType ? singleCourier?.singleCourier?.data?.vehicleType : 'none'}</p>
                            </div>

                            <div>
                                <label>{t('couriers.courier_details.car_model')}</label>
                                <p>{singleCourier?.singleCourier?.data?.vehicleModel ? singleCourier?.singleCourier?.data?.vehicleModel : 'none'}</p>
                            </div>

                            <div>
                                <label>{t('couriers.courier_details.plate_letter')}</label>
                                <p>{singleCourier?.singleCourier?.data?.plateLetters ? localStorage.getItem('lang') === 'ar' ? singleCourier?.singleCourier?.data?.plateLettersAr : singleCourier?.singleCourier?.data?.plateLetters : 'none'}</p>
                            </div>

                            <div>
                                <label>{t('couriers.courier_details.plate_numbers')}</label>
                                <p>{singleCourier?.singleCourier?.data?.plateNumbers ? localStorage.getItem('lang') === 'ar' ? singleCourier?.singleCourier?.data?.plateNumbersAr : singleCourier?.singleCourier?.data?.plateNumbers : 'none'}</p>
                            </div>

                            <div>
                                <label>{t('couriers.courier_details.car_year')}</label>
                                <p>{singleCourier?.singleCourier?.data?.yearOfProduction ? singleCourier?.singleCourier?.data?.yearOfProduction : 'none'}</p>
                            </div>
                        </div>

                        <div className="w-full capitalize font-semibold text-sm mt-4 [&>p]:text-[#333]">
                            <p>{t('couriers.courier_details.car_photo')} {localStorage.getItem('lang') === 'ar' ? '(الوجه والظهر)' : '(front & back)'}</p>

                            <div className="car-images-wrapper flex justify-between gap-3 items-center [&>div]:basis-[49%] [&>div]:w-full [&>div]:h-[150px] mt-3">
                                <div className="car-photo cursor-pointer">
                                    {singleCourier?.singleCourier?.data?.vehicleFirstPhoto ? <img src={singleCourier?.singleCourier?.data?.vehicleFirstPhoto} loading="lazy" onClick={(e) => handleOpen(e)} alt="NID-Front" className="w-full h-full rounded-lg object-cover" /> : <span className="block w-full h-full rounded-lg bg-[rgba(0,0,0,.2)]"></span>}
                                </div>

                                <div className="car-photo cursor-pointer">
                                    {singleCourier?.singleCourier?.data?.vehicleSecondPhoto ? <img src={singleCourier?.singleCourier?.data?.vehicleSecondPhoto} loading="lazy" onClick={(e) => handleOpen(e)} alt="NID-Back" className="w-full h-full rounded-lg object-cover" /> : <span className="block w-full h-full rounded-lg bg-[rgba(0,0,0,.2)]"></span>}
                                </div>
                            </div>
                        </div>

                        <div className="w-full capitalize font-semibold text-sm mt-4 [&>p]:text-[#333]">
                            <p>{t('couriers.courier_details.car_reg_photo')} {localStorage.getItem('lang') === 'ar' ? '(الوجه والظهر)' : '(front & back)'}</p>

                            <div className="car-images-wrapper flex justify-between gap-3 items-center [&>div]:basis-[49%] [&>div]:w-full [&>div]:h-[150px] mt-3">
                                <div className="car-photo">
                                    {singleCourier?.singleCourier?.data?.carRegisterationFrontPhoto ? <img src={singleCourier?.singleCourier?.data?.carRegisterationFrontPhoto} loading="lazy" onClick={(e) => handleOpen(e)} alt="NID-Front" className="w-full h-full rounded-lg object-cover cursor-pointer" /> : <span className="block w-full h-full rounded-lg bg-[rgba(0,0,0,.2)]"></span>}
                                </div>

                                <div className="car-photo">
                                    {singleCourier?.singleCourier?.data?.carRegisterationBackPhoto ? <img src={singleCourier?.singleCourier?.data?.carRegisterationBackPhoto} loading="lazy" onClick={(e) => handleOpen(e)} alt="NID-Back" className="w-full h-full rounded-lg object-cover cursor-pointer" /> : <span className="block w-full h-full rounded-lg bg-[rgba(0,0,0,.2)]"></span>}
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-wrap items-start gap-4 mt-4 [&>div]:basis-[40%] [&>div>label]:text-sm [&>div>label]:text-[#333] [&>div>label]:font-semibold [&>div>label]:mb-2 [&>div>p]:text-sm [&>div>p]:text-[#999999]">
                            <div>
                                <label>{t('couriers.courier_details.working_region')}</label>
                                <p>{singleCourier?.singleCourier?.data?.workingRegion ? singleCourier?.singleCourier?.data?.workingRegion : 'none'}</p>
                            </div>

                            <div>
                                <label>{t('couriers.courier_details.working_city')}</label>
                                <p>{singleCourier?.singleCourier?.data?.workingCity ? singleCourier?.singleCourier?.data?.workingCity : 'none'}</p>
                            </div>

                            {/* <div>
                                <label>Living Address</label>
                                <p>{singleCourier?.singleCourier?.data?.address ? singleCourier?.singleCourier?.data?.address : 'none'}</p>
                            </div> */}
                        </div>

                    </div>
                </div>

                <div className={`driving-btns-wrapper basis-full justify-items-end ${localStorage.getItem('lang') === 'ar' ? 'flex-row-reverse' : 'flex-row'}`}>
                    {auth?.role === 'SUPER_ADMIN' ?

                        <div className={`courier-submit-btn-wrapper mt-5 flex gap-x-4 justify-start ${localStorage.getItem('lang') === 'ar' ? 'flex-reverse' : 'flex-row-reverse'} [&>button]:px-5 [&>button]:py-2`}>
                            {location?.state?.type === 'request' ?
                                <>
                                    <Button onClick={() => toggleCourierHandler('accept')} disabled={singleCourier?.loading} className="disabled:bg-gray-400 disabled:opacity-50 disabled:cursor-not-allowed justify-center  p-0 bg-mainGreen text-white text-sm font-semibold rounded-md capitalize">{t('common.approve')}</Button>
                                    <Button onClick={handleOpenRejectModal} className="disabled:bg-gray-400 disabled:opacity-50 disabled:cursor-not-allowed justify-center  p-0 bg-mainRed text-white text-sm font-semibold rounded-md capitalize">{t('common.decline')}</Button>
                                </>
                                : location?.state?.type === 'rejected' ?
                                    null
                                    :
                                    location?.state?.type === 'status' ?
                                        <>
                                            {singleCourier?.singleCourier?.data?.isActive === false ?
                                                <Button onClick={() => toggleCourierHandler('activate')} disabled={singleCourier?.loading} className={`disabled:bg-gray-400 disabled:opacity-50 disabled:cursor-not-allowed justify-center  p-0 bg-mainGreen text-white text-sm font-semibold rounded-sm capitalize`}>{t('common.activate')}</Button>
                                                :
                                                <Button onClick={handleOpenDeactivate} disabled={singleCourier?.loading} className={`disabled:bg-gray-400 disabled:opacity-50 disabled:cursor-not-allowed justify-center  p-0 bg-mainRed text-white text-sm font-semibold rounded-sm capitalize`}>{t('common.deactivate')}</Button>
                                            }
                                        </>

                                        : null}

                            <Button onClick={cancelBtnHandler} disabled={singleCourier?.loading} className="disabled:bg-gray-400 disabled:opacity-50 disabled:cursor-not-allowed justify-center  p-0 bg-transparent text-mainRed border-[1px] border-solid border-mainRed text-sm capitalize font-semibold rounded-sm transition-all duration-300 hover:bg-mainRed hover:text-white">{t('common.cancel_btn')}</Button>
                        </div>

                        : null}

                    <DeactivateCourier open={openDeactivate} close={handleCloseDeactivate} id={id} loading={singleCourier?.loading} />
                </div>
            </section>



            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <img src={modalImg} loading="lazy" alt={`imgae-${modalImg}`} className="w-full h-full object-fill" />
                </Box>
            </Modal>

            <Modal
                open={openRejectModal}
                onClose={handleCloseRejectModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={rejectStyle}>
                    <form onSubmit={handleSubmit(rejectCourierSubmitHandler)}
                        className="[&>div>label]:font-semibold [&>div>label]:capitalize [&>div>label]:text-lg [&>div>label]:block [&>div>label]:text-mainColor [&>div>label]:mb-4
                                [&>div>textarea]:w-full [&>div>textarea]:shadow-sm [&>div>textarea]:resize-none [&>div>textarea]:h-[150px] [&>div>textarea]:border [&>div>textarea]:rounded-md [&>div>textarea]:p-2 [&>div>textarea]:text-mainColor"
                    >
                        <div>
                            <label>{t('couriers.courier_details.rejectReason')}</label>

                            <textarea
                                className="placeholder:capitalize"
                                placeholder={t('couriers.courier_details.rejectReason')}
                                {...register('reason', {
                                    required: {
                                        value: true,
                                        message: t('common.required')
                                    }
                                })}
                            >

                            </textarea>

                            {errors?.reason?.message ? <p className="capitalize text-sm text-danger">{errors?.reason?.message}</p> : null}
                        </div>

                        <div className="mt-6 flex items-center justify-around [&>button]:capitalize [&>button]:font-semibold [&>button]:px-6">
                            <Button onClick={handleCloseRejectModal} className="disabled:bg-gray-400 disabled:opacity-50 disabled:cursor-not-allowed justify-center  bg-transparent text-mainRed border border-solid border-mainRed">{t('common.cancel')}</Button>
                            <Button type="submit" disabled={singleCourier?.loading} className="disabled:bg-gray-400 disabled:opacity-50 disabled:cursor-not-allowed justify-center  bg-mainColor text-white">{t('common.confirm')}</Button>
                        </div>
                    </form>
                </Box>
            </Modal>

            <PayoutModal open={openPayout} close={handleClosePayout} type='details' id={id} data={data} />

            {singleCourier?.loading ? <Overlay /> : null}
        </article>
    );
}

export default CourierDetails;