import { useEffect, useState } from "react"
import { BiCheck } from "react-icons/bi";
import { IoMdClose } from "react-icons/io";

const useValidator = (value) => {

    const [passwordRules, setpasswordRules] = useState({
        length: false,
        special: false,
        number: false
    })

    const passwordValidator = (value) => {
        const rules = {
            length: value?.length >= 8,
            special: /[!@#$%^&*"()+\-,./:;<=>[\]{}_\\|`''""~?\s]/.test(value),
            number: /[0-9]/.test(value)
        };
        if (value === '') {
            rules.length = false;
            rules.special = false;
            rules.number = false;
        }
        setpasswordRules(rules);

        return Object.values(rules).every(rule => rule)
    };

    useEffect(() => {
        passwordValidator(value);
    }, [value]);

    const validatorBox = () => (
        <ul className="[&>li]:flex [&>li]:items-center [&>li]:gap-x-1 text-sm">
            <li className={`${passwordRules?.length ? 'text-mainGreen' : 'text-mainRed'}`}>{passwordRules?.length ? <BiCheck /> : <IoMdClose />} At least 8 characters long</li>
            <li className={`${passwordRules?.number ? 'text-mainGreen' : 'text-mainRed'}`}>{passwordRules?.number ? <BiCheck /> : <IoMdClose />} Contain at least one number</li>
            <li className={`${passwordRules?.special ? 'text-mainGreen' : 'text-mainRed'}`}>{passwordRules?.special ? <BiCheck /> : <IoMdClose />} Contain at least one special character</li>
        </ul>
    )


    return { validatorBox, passwordValidator }
}

export default useValidator;