import { useTranslation } from "react-i18next";
import DeliveryPackageTable from "./DP-Components/dp-table";
import ArchivedPackages from "./DP-Components/archivedPackages";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useState } from "react";
import { Box, Tab } from "@mui/material";




const DeliveryPackages = () => {
    const { t } = useTranslation()

    const [value, setValue] = useState(0);

    const handleChange = (_event, newValue) => {
        setValue(newValue);
    };

    const tabList = [
        { id: 1, title: t('dp.activeTab'), component: <DeliveryPackageTable t={t} /> },
        { id: 2, title: t('dp.archivedTab'), component: <ArchivedPackages t={t} /> },
    ]

    return (
        <article className="dp-wrapper">
            <section className="order-title">
                <h1 className="text-[#333] font-bold text-3xl mb-4 font-playfair capitalize">{t('dp.title')}</h1>
            </section>

            <section>
                <TabContext value={value}>
                    <Box
                        sx={{ '.MuiTabs-indicator': { backgroundColor: '#442b7e' }, '.Mui-selected': { color: "#555 !important" } }}
                    >
                        <TabList onChange={handleChange} variant="standard" sx={{ '&.MuiTabs-indicator': { backgroundColor: '#442b7e' } }}>
                            {tabList?.map((item, index) => (
                                <Tab key={index} label={item?.title} value={index} className="capitalize text-[#999999] font-bold text-lg font-Ubuntu" />
                            ))}
                        </TabList>
                    </Box>

                    <div>
                        {tabList?.map((item, index) => (
                            <TabPanel value={index} key={item?.id} sx={{ px: 0 }}>
                                {item?.component}
                            </TabPanel>
                        ))}
                    </div>
                </TabContext>
            </section>
        </article>
    );
}

export default DeliveryPackages;