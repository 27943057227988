import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { Autocomplete, Box, Button, FormControl, ListItemText, MenuItem, Modal, Select, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { IoCloseCircleOutline } from 'react-icons/io5'
import { useDispatch, useSelector } from 'react-redux';
import { IoMdAddCircle } from 'react-icons/io';
import { Controller, useForm } from 'react-hook-form';
import Overlay from '../../Components/Common/overlay';
import { getRoles } from '../../Components/Redux/Slice/Roles/roles';
import AllUsers from './Users_components/allUsers';
import BackOfficeUsers from './Users_components/backOfficeUsers';
import CustomerService from './Users_components/customerServiceUsers';
import SuperAdmins from './Users_components/superAdmins';
import Astrisks from "../../Components/Common/astrisk";
import PhoneInput from 'react-phone-input-2';
import { addUser, getBackOfficeUsers, getCustomerServiceUsers, getUsers } from '../../Components/Redux/Slice/Users/usersSlice';
import { useTranslation } from 'react-i18next';
import { getManagerRestaurants } from '../../Components/Redux/Slice/AccountManager/manager';
import AccountManager from "./Users_components/account_manager";
import { resetPage, resetSearch, resetSort } from "../../Components/Redux/Slice/ResetPagination/resetPagination";

//Custome Call UI for the data table action column


const Users = () => {
    const { t, i18n } = useTranslation()
    const dispatch = useDispatch()
    const [roleType, setroleType] = useState([])
    const [roleName, setroleName] = useState(null)
    const [restaurants, setrestaurants] = useState(null)
    const users = useSelector(state => state.users)

    const { roles } = useSelector(state => state?.roles)
    // console.log(roles);


    useEffect(() => {
        dispatch(getRoles())
        dispatch(getManagerRestaurants())
    }, [dispatch])

    const handleroleTypeSelectChange = (event) => {
        const { target: { value }, } = event;
        setroleType(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
        // console.log(value);
        setroleName(value?.roleName)
        setValue('roleId', value?.id, { shouldValidate: true })
    };


    //data table section

    const [openModal, setopenModal] = useState(false);
    const handleOpenModal = () => setopenModal(true);
    const handleCloseModal = () => {
        setopenModal(false);
        setroleType([])
        setroleName(null)
        reset()
    }

    const style = {
        position: 'absolute',
        top: '47%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        boxShadow: 3,
        p: 0,
        borderRadius: 5
    };


    // handle modal form
    let page = 1,
        row = 10
    const form = useForm()
    const { register, control, handleSubmit, formState, setValue, getValues, reset } = form
    const { errors, isDirty } = formState
    const submitHandler = async (data) => {
        await dispatch(addUser(data)).then(() => {
            dispatch(getUsers({ page, row }))
            dispatch(getBackOfficeUsers({ page, row }))
            dispatch(getCustomerServiceUsers({ page, row }))
            dispatch(resetSearch());
            dispatch(resetSort({ sort: true }))
            dispatch(resetPage())
            setroleType([])
            setroleName(null)
        }).then(() => reset()).then(() => handleCloseModal())
        // console.log(data);
    }

    // console.log(users);




    //Filter Tabs
    const [activeTab, setActiveTab] = useState(0);
    const [tab, settab] = useState('ALL');

    const btns = [
        { labelAr: 'الكل', label: 'all', tab: `ALL` },
        { labelAr: 'مدير حساب', label: 'account manager', tab: `account_manager` },
        { labelAr: 'مدير النظام الأعلى', label: 'super admin', tab: `admin` },
        { labelAr: 'إدارة الشكاوي', label: 'back office', tab: `back_office` },
        { labelAr: 'خدمة العملاء', label: 'customer service', tab: `customer_service` },
    ]

    const filterBtn = (item, index) => {
        setActiveTab(index)
        let tab = item?.tab
        settab(tab)
    }

    // Manager Restaurants

    const managerRestaurants = useSelector(state => state?.manager)
    const handleRestaurantSelectChange = (e, v) => {
        const { target: { value }, } = e;
        setrestaurants(typeof value === 'string' ? value.split(',') : value);

        let resIds = v?.map((item) => item?.id)

        setValue('restaurants', resIds, { shouldValidate: true })
    };

    const handleRestaurantSearch = (search) => {
        dispatch(getManagerRestaurants(search))
    };


    // console.log(managerRestaurants?.managerRestaurants?.data);

    return (
        <article className="users-wrapper">
            <section className="users-title">
                <h1 className="text-[#333] font-bold text-3xl font-playfair capitalize">{t('users.title')}</h1>
            </section>

            <section className='mt-4'>
                <div className={`basis-full flex flex-wrap lg:flex-nowrap gap-y-4 justify-between items-center tabs-wrapper rounded-md overflow-hidden my-2 ${localStorage.getItem('lang') === 'ar' ? 'flex-row-reverse' : 'flex-row'}`}>

                    <div className="px-2 py-1 bg-[#ffffff] w-fit basis-full lg:basis-auto">
                        {btns?.map((item, index) => {
                            return (
                                <button key={index} className={`capitalize font-bold text-sm text-[#333] font-playfair px-4 py-2 lg:px-2 xl:px-4 2xl:px-6 rounded-sm transition-all duration-300 ${activeTab === index ? 'bg-[#F4F6FC]' : null}`}
                                    onClick={() => filterBtn(item, index)}
                                >
                                    {localStorage.getItem('lang') === 'ar' ? item.labelAr : item.label}
                                </button>
                            )
                        })}
                    </div>

                    <div className={` justify-start ${localStorage.getItem('lang') === 'ar' ? 'mr-[8rem]' : 'mr-[10rem]'}`}>
                        <Button onClick={handleOpenModal} className='capitalize text-white font-semibold bg-mainGreen rounded-md py-2 flex justify-between gap-x-2'>
                            <IoMdAddCircle className='w-6 h-6 rounded-[50%]' />
                            {t('users.add_users_btn')}
                        </Button>
                    </div>

                </div>

                {tab === 'ALL' ?
                    <AllUsers t={t} />

                    : tab === 'admin' ?
                        <SuperAdmins t={t} />

                        : tab === 'account_manager' ?
                            <AccountManager t={t} />

                            : tab === 'back_office' ?
                                <BackOfficeUsers t={t} />

                                : tab === 'customer_service' ?
                                    <CustomerService t={t} />

                                    : null}
            </section>



            <>
                <Modal
                    open={openModal}
                    onClose={() => {
                        handleCloseModal()
                    }}
                    aria-labelledby="menu-item-delete-modal-title"
                    aria-describedby="menu-item-delete-modal-description"
                >
                    <Box
                        sx={style}
                        dir={localStorage?.getItem('lang') === 'ar' ? 'rtl' : 'ltr'}
                    >
                        <form onSubmit={handleSubmit(submitHandler)} noValidate className='py-3 px-1'>
                            <div className="flex justify-between items-center mb-8 border-b px-4 pb-0 pt-5">
                                <h2 className="text-[#333333] text-lg font-bold mb-5 capitalize font-playfair">{t('users.add_users_btn')}</h2>

                                <div>
                                    <Button color="error" className="text-2xl -mt-2" onClick={handleCloseModal}><IoCloseCircleOutline /></Button>
                                </div>
                            </div>

                            <div className='p-5 py-0 flex flex-wrap justify-between items-center md:gap-x-4 [&>div]:mb-3 [&>div>label]:capitalize [&>div>label]:text-[#333] [&>div>label]:text-sm [&>div>label]:block [&>div>label]:mb-2 [&>div>label]:font-semibold [&>div>input]:bg-[#f3f6f9] [&>div>input]:w-full [&>div>input]:p-3 [&>div>input]:focus:outline-slate-400 [&>div>input]:rounded-md [&>div>input]:text-mainColor [&>div>input]:mb-1'>

                                <div className='basis-full md:basis-[48%]'>
                                    <label>{t('users.form.first_name')} <Astrisks /></label>
                                    <input type="text" id="firstName"
                                        {...register('firstName', {
                                            required: {
                                                value: true,
                                                message: t('common.required')
                                            },
                                            minLength: {
                                                value: 3,
                                                message: t('common.min_length', { length: '3' })
                                            },
                                            maxLength: {
                                                value: 50,
                                                message: t('common.max_length', { length: '50' })
                                            },
                                        })}
                                        minLength={3}
                                        maxLength={50}

                                    />

                                    {errors?.firstName?.message ? <p className="capitalize text-sm text-danger">{errors?.firstName?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('users.form.first_name_helper')}</p>}
                                </div>

                                <div className='basis-full md:basis-[48%]'>
                                    <label>{t('users.form.last_name')} <Astrisks /></label>
                                    <input type="text" id="lastName"
                                        {...register('lastName', {
                                            required: {
                                                value: true,
                                                message: t('common.required')
                                            },
                                            minLength: {
                                                value: 3,
                                                message: t('common.min_length', { length: '3' })
                                            },
                                            maxLength: {
                                                value: 50,
                                                message: t('common.max_length', { length: '50' })
                                            },
                                        })}
                                        minLength={3}
                                        maxLength={50}

                                    />

                                    {errors?.lastName?.message ? <p className="capitalize text-sm text-danger">{errors?.lastName?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('users.form.last_name_helper')}</p>}
                                </div>

                                <div className='basis-full md:basis-[48%]' dir='ltr'>
                                    <label dir={localStorage?.getItem('lang') === 'ar' ? 'rtl' : 'ltr'}>{t('users.form.phone')} <Astrisks /></label>
                                    {/* <input type="text" id="phoneNumber"
                                        {...register('phoneNumber',{
                                            required:{
                                                value : true,
                                                message : t('common.required')
                                            },
                                            minLength : {
                                                value : 10,
                                                message : 'min length is 10 digits'
                                            },
                                            maxLength : {
                                                value : 22,
                                                message : 'max length is 22 digits'
                                            },                                   
                                            pattern:{
                                                value: /^(\+)?\d+$/,
                                                message: 'Only numbers and (+) sign the beginning allowed'
                                            }
                                        })} 
                                        minLength={10}
                                        maxLength={22}
                                    /> */}

                                    <Controller
                                        name="phoneNumber"
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: t('common.required')
                                            },
                                            minLength: {
                                                value: 10,
                                                message: t('common.min_length', { length: '10' })
                                            },
                                            maxLength: {
                                                value: 22,
                                                message: t('common.max_length', { length: '22' })
                                            },
                                            pattern: {
                                                value: /^(\+)?\d+$/,
                                                message: localStorage.getItem('lang') === 'ar' ? 'مسموح بالارقام وعلامه + بالبداية فقط' : 'Only numbers and (+) sign the beginning allowed'
                                            }
                                        }}
                                        render={({ field: { onChange, value } }) => (
                                            <PhoneInput
                                                className='mb-1 [&>input]:bg-[#f3f6f9] [&>input]:w-full [&>input]:h-auto [&>input]:p-3 [&>input]:pl-12 [&>input]:border-none [&>input]:focus:outline-slate-400 [&>input]:rounded-md [&>input]:text-mainColor'
                                                value={value}
                                                dir={localStorage?.getItem('lang') === 'ar' ? 'rtl' : 'ltr'}
                                                onChange={(val, country) => {
                                                    onChange("+" + val); // Add '+' sign before the value
                                                }}
                                                country={'sa'}
                                                minLength={10}
                                                maxLength={22}
                                                preserveOrder={['+', '']}
                                                enableSearch={true}
                                                disableSearchIcon
                                            />
                                        )}
                                    />

                                    {errors?.phoneNumber?.message ? <p className={`capitalize text-sm text-danger ${localStorage.getItem('lang') === 'ar' ? 'text-right' : 'text-left'}`}>{errors?.phoneNumber?.message}</p> : <p className={`capitalize text-[11px] text-[#B5B5C3] ${localStorage.getItem('lang') === 'ar' ? 'text-right' : 'text-left'} `}>{t('users.form.phone_helper')}</p>}
                                </div>

                                <div className='basis-full md:basis-[48%]'>
                                    <label>{t('users.form.email')} <Astrisks /></label>
                                    <input type="email" id="email"
                                        {...register('email', {
                                            pattern: {
                                                value: /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-]+)(\.[a-zA-Z]{2,5}){1,2}$/,
                                                message: localStorage.getItem('lang') === 'ar' ? 'خطا بصيغه الايميل' : 'Wrong Email Format'
                                            },
                                            required: {
                                                value: true,
                                                message: t('common.required')
                                            }
                                        })} />

                                    {errors?.email?.message ? <p className="capitalize text-sm text-danger">{errors?.email?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('users.form.email_helper')}</p>}
                                </div>

                                <div className='basis-full md:basis-[48%]'>
                                    <label htmlFor="gender">{t('users.form.gender')} <Astrisks /></label>
                                    <Controller
                                        name="gender"
                                        control={control}
                                        defaultValue=""
                                        rules={{ required: t('common.required') }}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <>
                                                <select onChange={(e) => { onChange(e) }} value={value} className="w-full bg-[#f3f6f9] p-3 rounded-md text-mainColor capitalize mb-1">
                                                    <option value="" disabled>
                                                        {t('users.form.gender_helper')}
                                                    </option>
                                                    <option value={'MALE'}>{t('common.male')}</option>
                                                    <option value={'FEMALE'}>{t('common.female')}</option>
                                                </select>
                                                {error ? <p className="capitalize text-sm text-danger">{error.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('users.form.gender_helper')}</p>}
                                            </>
                                        )}
                                    />
                                </div>

                                <div className='basis-full md:basis-[48%]'>
                                    <label>{t('users.form.nid')} <Astrisks /></label>
                                    <input type="text" id="nationalId"
                                        {...register('nationalId', {
                                            required: {
                                                value: true,
                                                message: t('common.required')
                                            },
                                            minLength: {
                                                value: 10,
                                                message: t('common.min_length', { length: '10' })
                                            },
                                        })}
                                        maxLength={10}
                                    />

                                    {errors?.nationalId?.message ? <p className="capitalize text-sm text-danger">{errors?.nationalId?.message}</p> : <p className='capitalize text-[11px] text-[#B5B5C3]'>{t('users.form.nid_helper')}</p>}
                                </div>

                                <div className={`basis-full [&>div]:bg-[#f3f6f9]`}>
                                    <label>{t('users.form.select_role')} <Astrisks /></label>
                                    <FormControl sx={{ width: '100%' }}>
                                        <Controller
                                            control={control}
                                            name="roleId"
                                            value={roleType}
                                            rules={{ required: t('common.required') }}
                                            render={({ field, fieldState: { error } }) => (
                                                <>
                                                    <Select {...field}
                                                        displayEmpty
                                                        // inputRef={{...register('roleId')}}
                                                        labelId="demo-multiple-checkbox-label"
                                                        id="roleId"
                                                        value={roleType}
                                                        onChange={handleroleTypeSelectChange}
                                                        className={`${localStorage.getItem('lang') === 'ar' ? 'text-right' : 'text-left'}`}
                                                        // renderValue={(selected) => selected.join(', ')}
                                                        sx={{ '& .MuiList-root': { textAlign: 'right' }, '& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': { padding: '11px 14px' }, '& fieldset': { border: 0 }, '& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': { backgroundColor: '#f3f6f9' }, '& .css-zx5rfr': { backgroundColor: '#f3f6f9' } }}
                                                    >
                                                        <MenuItem value={''} disabled className={`capitalize ${localStorage.getItem('lang') === 'ar' ? 'justify-end' : 'justify-start'}`}>
                                                            {t('users.form.select_role_helper')}
                                                        </MenuItem>
                                                        {roles?.data?.map((role) => (
                                                            <MenuItem value={role} key={role?.id}>
                                                                <ListItemText className={`capitalize ${localStorage.getItem('lang') === 'ar' ? 'text-right' : 'text-left'}`} primary={localStorage.getItem('lang') === 'ar' ? role?.roleNameAr : role?.roleName?.toLowerCase()?.replace(/_/g, " ")} />
                                                            </MenuItem>
                                                        ))}


                                                    </Select>
                                                </>
                                            )}

                                        />

                                    </FormControl>

                                    {errors?.roleId?.message ? <p className="capitalize text-sm text-danger mt-1">{errors?.roleId?.message}</p> : <p className='capitalize text-[11px] text-[#B5B5C3] mt-1'>{t('users.form.select_role_helper')}</p>}
                                </div>

                                {roleName === 'ACCOUNT_MANAGER' ?

                                    <div className="basis-full [&>div]:bg-[#f3f6f9]">
                                        <label>{t('users.form.select_restaurant')} <Astrisks /></label>
                                        <FormControl sx={{ width: '100%' }}>
                                            <Controller
                                                control={control}
                                                name="restaurants"
                                                value={restaurants || []}
                                                rules={{ required: t('common.required') }}
                                                render={({ field, fieldState: { error } }) => (
                                                    <>
                                                        <Autocomplete
                                                            loading={managerRestaurants?.mrLoading}
                                                            loadingText={<div className='w-full h-full text-center text-gray-400 flex items-center justify-center gap-x-2'>Loading... <AiOutlineLoading3Quarters className="animate-spin text-xl" /></div>}
                                                            disablePortal
                                                            onChange={(e, v) => handleRestaurantSelectChange(e, v)}
                                                            id="restaurants"
                                                            multiple
                                                            disableCloseOnSelect
                                                            onClose={() => dispatch(getManagerRestaurants())}
                                                            options={managerRestaurants?.managerRestaurants?.data}
                                                            getOptionLabel={(option) => option?.name}
                                                            sx={{ width: '100%', '& fieldset': { border: 'none', outline: 'none' }, '& .MuiOutlinedInput-root': { padding: '6px', borderRadius: '6px' } }}
                                                            renderInput={(params) => <TextField onChange={(e) => handleRestaurantSearch(e?.target?.value)} {...params} />}
                                                        />
                                                    </>
                                                )}

                                            />

                                        </FormControl>

                                        {errors?.restaurants?.message ? <p className="capitalize text-sm text-danger mt-1">{errors?.restaurants?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3] mt-1">{t('users.form.select_restaurant_helper')}</p>}
                                    </div> : null}
                            </div>

                            <div className={`add-user-submit-btn flex justify-end w-full mt-7 px-4 py-2 ${localStorage?.getItem('lang') === 'ar' ? 'flex-row-reverse' : 'flex-row'}`}>
                                <Button type='submit' disabled={users?.loading} className='disabled:opacity-50 disabled:cursor-not-allowed capitalize bg-mainColor text-white w-28'>{t('users.form.add_btn')}</Button>
                            </div>
                        </form>
                    </Box>
                </Modal>

                {users?.loading ? <Overlay /> : null}
            </>


        </article>
    );
}

export default Users;