import { Box, Button, FormControl, IconButton, InputAdornment, ListItemButton, Menu, MenuItem, Modal, OutlinedInput, Select } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { assignTicket, getAllTickets, toggleTicket } from "../../../Components/Redux/Slice/Tickets/tickets";
import { useDispatch, useSelector } from "react-redux";
import { dateFormate } from "../../../Components/Common/dateFormate";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import { FaAngleDown, FaEye, FaRegHandPointRight } from "react-icons/fa";
import { Controller, useForm } from "react-hook-form";
import { IoCloseCircleOutline } from "react-icons/io5";
import { openHandler } from "../../../Components/Redux/Slice/common/overlaySlice";
import { DateRangePicker } from "react-date-range";
import { CiSearch } from "react-icons/ci";
import ColumnSelection from "../../../Components/Common/columnSelection";
import { BsDot } from "react-icons/bs";
import { useRemoteSort } from "../../../Components/Common/sortHook";
import { getBackOfficeStuff } from '../../../Components/Redux/Slice/Users/usersSlice'
import NoDataEmoji from "../../../Components/Common/nodataEmoje";
import { TableStyles } from '../../../Components/Common/tableStyle'
import { useTranslation } from "react-i18next";
import { resetPage, resetSearch, resetSort } from "../../../Components/Redux/Slice/ResetPagination/resetPagination";
import dayjs from "dayjs";

const ActionCell = ({ data }) => {
    const { t, i18n } = useTranslation()
    const dispatch = useDispatch()
    const [users, setusers] = useState([])
    const form = useForm()
    const { register, control, handleSubmit, formState, setValue, getValues, reset } = form
    const { errors, isDirty } = formState
    const { backOfficeStuff } = useSelector(state => state?.users)

    let id = data?.id
    // console.log(backOfficeStuff);
    // console.log(data);


    //Assign Modal
    const [openModal, setopenModal] = useState(false)
    const handleOpenModal = () => setopenModal(true)
    const handleCloseModal = () => {
        setopenModal(false)
        reset()
        setusers([])
    }

    const style = {
        position: 'absolute',
        top: '47%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        boxShadow: 3,
        p: 0,
        borderRadius: 5
    };

    const handleUserSelectChange = (event) => {
        const { target: { value }, } = event;
        setusers(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
        setValue('userId', value, { shouldValidate: true, shouldDirty: true })
    };

    let page = 1,
        size = 10
    const submitHandler = async (userId) => {
        await dispatch(assignTicket({ userId, id })).then((e) => {
            if (e?.payload?.message === "SUCCESS") {
                handleCloseModal()
                reset()
                dispatch(getAllTickets({ page, size }))
                dispatch(resetPage());
            }
        })
    }

    // const [showList, setshowList] = useState(true)
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const { auth } = useSelector(state => state)


    const [openTicket, setopenTicket] = useState(false);
    const [type, settype] = useState('');

    const resolveStyle = {
        position: 'absolute',
        top: '45%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 'auto',
        minWidth: '500px',
        height: 'auto',
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        boxShadow: 2,
        py: 4,
        px: 2,
        borderRadius: 5,
    };

    useEffect(() => {
        reset()
    }, [type])

    const handleOpen = (e) => {
        setopenTicket(true)
        settype(e);
        // console.log(e);
    };
    const handleCloseTicket = () => {
        setopenTicket(false)
        reset()
    };

    const submitResolveHandler = async (values) => {
        await dispatch(toggleTicket({ values, id, type })).then((e) => {
            if (e?.payload?.message === "SUCCESS") {
                handleClose()
                reset()
                dispatch(getAllTickets({ page, size }))
            }
        })
    }

    return (
        <div className='action-wrapper relative'>
            <ListItemButton className='rounded-md' onClick={handleClick}><span><BiDotsHorizontalRounded className='text-xl' /></span></ListItemButton>
            <Menu
                id="datatable-action-menu"
                aria-labelledby="datatable-action-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                PaperProps={{ style: { boxShadow: 'none', padding: 0 } }}
                className='shadow-md p-0'
            >
                <div className='[&>li]:mb-1 [&>li>svg]:mr-2 [&>li>svg]:text-xl rounded-md overflow-hidden capitalize'>
                    <MenuItem className='text-[#6993FF] hover:bg-[#6994ff42] p-2'><Link to='details' state={{ data }} className={`w-full flex items-center gap-x-3 ${localStorage.getItem('lang') === 'ar' ? 'flex-row-reverse' : 'flex-row'}`}><FaEye />{localStorage.getItem('lang') === 'ar' ? 'عرض الشكوى' : 'View Ticket'}</Link></MenuItem>

                    {auth?.role === 'SUPER_ADMIN' ?
                        <MenuItem className='text-[#6993FF] hover:bg-[#6994ff42] p-2'>
                            <div className='action-wrapper relative'>
                                <span className={`capitalize text-base text-mainColor flex items-center gap-x-3 w-full ${localStorage.getItem('lang') === 'ar' ? 'flex-row-reverse' : 'flex-row'}`} onClick={handleOpenModal}>
                                    <FaRegHandPointRight />
                                    {localStorage.getItem('lang') === 'ar' ? 'خصص الى' : 'Assign To'}
                                </span>

                                <>
                                    <Modal
                                        open={openModal}
                                        onClose={handleCloseModal}
                                        aria-labelledby="menu-item-delete-modal-title"
                                        aria-describedby="menu-item-delete-modal-description"
                                    >
                                        <Box sx={style} dir={localStorage.getItem('lang') === 'ar' ? 'rtl' : 'ltr'}>
                                            <form onSubmit={handleSubmit(submitHandler)} noValidate className='py-3 px-1'>
                                                <div className="flex justify-between items-center mb-4 border-b px-4 pb-0 pt-5">
                                                    <h2 className="text-[#333333] text-lg font-bold mb-5 capitalize">{t('trouble_tickets.assign_modal.title')}</h2>

                                                    <div>
                                                        <Button color="error" className="text-2xl -mt-2" onClick={handleCloseModal}><IoCloseCircleOutline /></Button>
                                                    </div>
                                                </div>

                                                <div className='p-5 py-0 flex flex-wrap justify-between items-center md:gap-x-4 [&>div]:mb-3 [&>div>label]:capitalize [&>div>label]:text-[#333] [&>div>label]:text-sm [&>div>label]:block [&>div>label]:mb-2 [&>div>label]:font-semibold [&>div>input]:bg-[#f3f6f9] [&>div>input]:w-full [&>div>input]:p-3 [&>div>input]:focus:outline-slate-400 [&>div>input]:rounded-md [&>div>input]:text-mainColor [&>div>input]:mb-1'>
                                                    <div className="basis-full">
                                                        <label>{t('trouble_tickets.assign_modal.form.assign_to')}</label>
                                                        <FormControl sx={{ width: '100%' }}>
                                                            <Controller
                                                                control={control}
                                                                name=""
                                                                value={users}
                                                                rules={{ required: t('common.required') }}
                                                                {...register('userId', {
                                                                    required: {
                                                                        value: true,
                                                                        message: t('common.required')
                                                                    }
                                                                })}
                                                                render={({ field, fieldState: { error } }) => (
                                                                    <>
                                                                        <Select {...field}
                                                                            displayEmpty
                                                                            inputRef={{ ...register('userId') }}
                                                                            labelId="demo-multiple-checkbox-label"
                                                                            id="userId"
                                                                            value={users}
                                                                            onChange={handleUserSelectChange}
                                                                            // renderValue={(selected) => selected.join(', ')}
                                                                            sx={{ '& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': { padding: '11px 14px' }, '& fieldset': { border: 0 }, '& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': { backgroundColor: '#f3f6f9' } }}
                                                                        >
                                                                            <MenuItem value={''} disabled>
                                                                                {t('trouble_tickets.assign_modal.form.menu_helper')}
                                                                            </MenuItem>
                                                                            {backOfficeStuff?.data?.map((user) => (
                                                                                <MenuItem value={user?.id} key={user?.id}>
                                                                                    {/* <ListItemText className='capitalize' primary={user?.username?.toLowerCase()?.replace(/_/g," ")} /> */}
                                                                                    <div className="[&>span]:block [&>span]:w-full">
                                                                                        <span className="capitalize">{user?.firstName + " " + user?.lastName}</span>
                                                                                        <span className="text-sm text-gray-400">{user?.email}</span>
                                                                                    </div>
                                                                                </MenuItem>
                                                                            ))}


                                                                        </Select>
                                                                    </>
                                                                )}

                                                            />

                                                        </FormControl>

                                                        {errors?.branch_id?.message ? <p className="capitalize text-sm text-danger mt-1">{errors?.branch_id?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3] mt-1">{t('trouble_tickets.assign_modal.form.menu_helper')}</p>}
                                                    </div>
                                                </div>

                                                <div className='add-user-submit-btn flex justify-end w-full mt-7 px-4 py-2'>
                                                    <Button type='submit' disabled={users?.loading} className='disabled:opacity-50 disabled:cursor-not-allowed capitalize bg-mainColor text-white w-28'>{t('trouble_tickets.assign_modal.form.assign_btn')}</Button>
                                                </div>
                                            </form>
                                        </Box>
                                    </Modal>
                                </>
                            </div>
                        </MenuItem>
                        : null
                    }

                    {
                        auth?.role === 'BACK_OFFICE' && (data?.status === 'OPEN' || data?.status === 'REOPENED') ?

                            <>
                                <MenuItem className='text-[#6993FF] hover:bg-[#6994ff42] p-2 w-full'>
                                    <div className='action-wrapper relative w-full'>
                                        <Button className="capitalize text-white bg-mainGreen py-1 px-4 rounded-md w-full" onClick={() => handleOpen('resolve')} >
                                            {localStorage.getItem('lang') === 'ar' ? 'حسم' : 'Resolve'}
                                        </Button>
                                    </div>
                                </MenuItem>

                                <MenuItem className='text-[#6993FF] hover:bg-[#6994ff42] p-2 w-full'>
                                    <div className='action-wrapper relative w-full'>
                                        <Button className="capitalize text-white bg-mainColor py-1 px-4 rounded-md w-full" onClick={() => handleOpen('close')}>
                                            {localStorage.getItem('lang') === 'ar' ? 'إغلاق' : 'Close'}
                                        </Button>
                                    </div>
                                </MenuItem>

                                <Modal
                                    open={openTicket}
                                    onClose={handleCloseTicket}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                >
                                    <Box sx={resolveStyle} dir={localStorage.getItem('lang') === 'ar' ? 'rtl' : 'ltr'}>
                                        <div className="flex justify-between items-center border-b pb-2 mb-3">
                                            <h1 className="text-xl text-[#333] capitalize font-semibold">
                                                {type === 'resolve' ? t('trouble_tickets.assign_modal.form.ticket_resolution') : type === 'close' ? t('trouble_tickets.assign_modal.form.close_ticket') : type === 're-open' ? t('trouble_tickets.assign_modal.form.close_ticket') : ''}

                                            </h1>

                                            {/* <div>
                                            <FaTimesCircle onClick={handleClearanceClose} className="text-mainRed text-xl cursor-pointer" />
                                        </div> */}
                                        </div>

                                        <form noValidate onSubmit={handleSubmit(submitResolveHandler)}>
                                            <div className=" [&>div>label]:capitalize [&>div>label]:text-[#333] [&>div>label]:text-sm [&>div>label]:block [&>div>label]:mb-2 [&>div>label]:font-semibold [&>div>textarea]:bg-[#f3f6f9] [&>div>textarea]:w-full [&>div>textarea]:p-3 [&>div>textarea]:focus:outline-slate-400 [&>div>textarea]:rounded-md [&>div>textarea]:text-mainColor [&>div>textarea]:mb-1 [&>div>textarea]:resize-none">

                                                <div>
                                                    <label> {type === 'resolve' ? t('trouble_tickets.assign_modal.form.summary') : type === 'close' ? t('trouble_tickets.assign_modal.form.reason') : type === 're-open' ? t('trouble_tickets.assign_modal.form.reason') : ''} </label>
                                                    <textarea
                                                        id={`${type === 'resolve' ? 'resolution' : type === 'close' ? 'reason' : type === 're-open' ? 'reason' : ''}`}
                                                        {...register(`${type === 'resolve' ? 'resolution' : type === 'close' ? 'reason' : type === 're-open' ? 'reason' : ''}`, {
                                                            required: {
                                                                value: true,
                                                                message: t('common.required')
                                                            }
                                                        })}
                                                    />

                                                    {
                                                        type === 'resolve' ?
                                                            <p className="capitalize text-sm text-danger">{errors?.resolution?.message}</p>
                                                            :
                                                            type === 'close' ?
                                                                <p className="capitalize text-sm text-danger">{errors?.reason?.message}</p>
                                                                :
                                                                type === 're-open' ?
                                                                    <p className="capitalize text-sm text-danger">{errors?.reason?.message}</p>
                                                                    : null
                                                    }
                                                </div>

                                                <div className="flex justify-end item-center gap-x-4 mt-6 mb-0 capitalize text-sm [&>button]:text-white [&>button]:rounded-lg [&>button]:px-4">
                                                    <Button className="bg-gray-400" onClick={handleCloseTicket}>{t('common.cancel_btn')}</Button>
                                                    <Button className="bg-mainColor disabled:bg-gray-400 disabled:opacity-50 disabled:cursor-not-allowed" type="submit" disabled={''}>{t('common.submit')}</Button>
                                                </div>
                                            </div>
                                        </form>
                                    </Box>
                                </Modal>
                            </>

                            : null
                    }
                </div>
            </Menu>
        </div>
    );
}

// function AssignToModal( {data} ) {
//     const dispatch = useDispatch()
//     const {backOfficeStuff} = useSelector(state=>state?.users)
//     const [users, setusers] = useState([])
//     const form = useForm()
//     const {register,control,handleSubmit,formState,setValue,getValues,reset} = form
//     const {errors ,isDirty} = formState

//     useEffect(() => {
//         dispatch(getBackOfficeStuff())
//     }, [dispatch])

//     let id = data?.id
//     // console.log(backOfficeStuff);
//     //Assign Modal 
//     const [openModal, setopenModal] = useState(false)
//     const handleOpenModal = ()=>setopenModal(true)
//     const handleCloseModal = ()=>{
//         setopenModal(false)
//         reset()
//         setusers([])
//     }

//     const style = {
//         position: 'absolute',
//         top: '47%',
//         left: '50%',
//         transform: 'translate(-50%, -50%)',
//         width: 600,
//         bgcolor: 'background.paper',
//         // border: '2px solid #000',
//         boxShadow: 3,
//         p: 0,
//         borderRadius : 5
//     };

//     const handleUserSelectChange = (event) => {
//         const { target: { value },} = event;
//         setusers(
//         // On autofill we get a stringified value.
//         typeof value === 'string' ? value.split(',') : value,
//         );
//         setValue('userId', value,{shouldValidate:true,shouldDirty:true})
//     };

//     let page = 1,
//         row = 10
//     const submitHandler = async (userId)=>{
//         await dispatch(assignTicket({userId,id})).then( (e)=>{
//             if(e?.payload?.message === "SUCCESS"){
//                 handleCloseModal()
//                 reset()
//                 dispatch(getAllTickets({page,row}))
//             }
//         } )
//     }
//     console.log(data);

//     return (
//         <div className='action-wrapper relative'>
//             <Button className="capitalize text-white bg-mainColor rounded-full text-[13px]" onClick={handleOpenModal}>
//                 Assign To
//             </Button>

//             <>
//                 <Modal
//                     open={openModal}
//                     onClose={handleCloseModal}
//                     aria-labelledby="menu-item-delete-modal-title"
//                     aria-describedby="menu-item-delete-modal-description"
//                 >
//                     <Box sx={style}>
//                         <form onSubmit={handleSubmit(submitHandler)} noValidate className='py-3 px-1'>
//                             <div className="flex justify-between items-center mb-4 border-b px-4 pb-0 pt-5">
//                                 <h2 className="text-[#333333] text-lg font-bold mb-5 capitalize">Assign Ticket</h2>

//                                 <div>
//                                     <Button color="error" className="text-2xl -mt-2" onClick={handleCloseModal}><IoCloseCircleOutline /></Button>
//                                 </div>
//                             </div>

//                             <div className='p-5 py-0 flex flex-wrap justify-between items-center md:gap-x-4 [&>div]:mb-3 [&>div>label]:capitalize [&>div>label]:text-[#333] [&>div>label]:text-sm [&>div>label]:block [&>div>label]:mb-2 [&>div>label]:font-semibold [&>div>input]:bg-[#f3f6f9] [&>div>input]:w-full [&>div>input]:p-3 [&>div>input]:focus:outline-slate-400 [&>div>input]:rounded-md [&>div>input]:text-mainColor [&>div>input]:mb-1'>
//                                 <div className="basis-full">
//                                     <label>Assign To</label>
//                                     <FormControl sx={{width: '100%' }}>
//                                         <Controller
//                                             control={control}
//                                             name=""
//                                             value={users}
//                                             rules={{required:"Must Select An Option"}}
//                                             {...register('userId',{
//                                                 required:{
//                                                     value : true,
//                                                     message: 'This field is required'
//                                                 }
//                                             })}
//                                             render={({field,fieldState: { error }})=>(
//                                                 <>
//                                                     <Select {...field}
//                                                         displayEmpty
//                                                         inputRef={{...register('userId')}}
//                                                         labelId="demo-multiple-checkbox-label"
//                                                         id="userId"
//                                                         value={users}
//                                                         onChange={handleUserSelectChange}
//                                                         // renderValue={(selected) => selected.join(', ')}
//                                                         sx={{'& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input' : {padding:'11px 14px'},'& fieldset':{border : 0},'& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input' : {backgroundColor : '#f3f6f9'}}}
//                                                     >
//                                                         <MenuItem value={''} disabled>
//                                                             Select User
//                                                         </MenuItem>
//                                                         {backOfficeStuff?.data?.map( (user)=>(
//                                                             <MenuItem value={user?.id} key={user?.id}>
//                                                                 {/* <ListItemText className='capitalize' primary={user?.username?.toLowerCase()?.replace(/_/g," ")} /> */}
//                                                                 <div className="[&>span]:block [&>span]:w-full">
//                                                                     <span className="capitalize">{user?.username?.toLowerCase()?.replace(/_/g," ")}</span>
//                                                                     <span className="text-sm text-gray-400">{user?.email}</span>
//                                                                 </div>
//                                                             </MenuItem>
//                                                         ) )}


//                                                     </Select>                                                    
//                                                 </>
//                                             )}

//                                         />

//                                     </FormControl>

//                                     {errors?.branch_id?.message ? <p className="capitalize text-sm text-danger mt-1">{errors?.branch_id?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3] mt-1">please select User</p>}
//                                 </div>
//                             </div>

//                             <div className='add-user-submit-btn flex justify-end w-full mt-7 px-4 py-2'>
//                                 <Button type='submit' disabled={users?.loading } className='disabled:opacity-50 disabled:cursor-not-allowed capitalize bg-mainColor text-white w-28'>Assign</Button>
//                             </div>
//                         </form>
//                     </Box>
//                 </Modal>
//             </>
//         </div>
//     );
// }


const AllTickets = ({ t }) => {
    const dispatch = useDispatch()
    const allTickets = useSelector(state => state?.tickets)
    const { backOfficeStuff } = useSelector(state => state?.users)
    const [page, setpage] = useState(1)
    const [size, setsize] = useState(10)
    const [selectedDate, setSelectedDate] = useState(null); // for storing the selected date from date picker
    // const [col, setcol] = useState(undefined)
    // const [dir, setdir] = useState('asc')
    const [searchValue, setsearchValue] = useState('')
    const { auth } = useSelector(state => state)
    const navigate = useNavigate()

    const { currentPage, toggle, sort, search } = useSelector((state) => state?.resetPagination);

    // console.log(backOfficeStuff);

    useEffect(() => {
        setpage(currentPage)
    }, [toggle])

    useEffect(() => {
        setsearchValue('')
    }, [search])

    useEffect(() => {
        dispatch(getAllTickets({ page, size }))

        if (auth?.role === 'SUPER_ADMIN') {
            dispatch(getBackOfficeStuff())
        }
    }, [dispatch, auth?.role])

    const columns = [
        // {
        //     name: 'ID',
        //     selector: row => row.id,
        //     // sortable: true,
        //     width:'70px'
        // },
        {
            id: "assignedTo",
            name: localStorage.getItem('lang') === 'ar' ? 'مخصص الى' : 'Assigned To',
            selector: row => row?.assignedTo ? row?.assignedTo?.firstName + " " + row?.assignedTo?.lastName : <span data-tag='allowRowEvents' className="text-mainRed font-semibold">{t('common.unAssigned')}</span>,
            sortable: false,
        },
        {
            id: "assigneeName",
            name: localStorage.getItem('lang') === 'ar' ? 'اسم المحال اليه' : 'Assignee Name',
            selector: row => row?.assigneeName ? row?.assigneeName : <span data-tag='allowRowEvents' className="text-gray-600 font-semibold">{t('common.none')}</span>,
            sortable: true,
        },
        {
            id: "assigneeUsername",
            name: localStorage.getItem('lang') === 'ar' ? 'اسم المستخدم المحال اليه' : 'Assignee User Name',
            selector: row => row?.assigneeUsername ? row?.assigneeUsername : <span data-tag='allowRowEvents' className="text-gray-600 font-semibold">{t('common.none')}</span>,
            sortable: true,
        },
        {
            id: "assigneeEmail",
            name: localStorage.getItem('lang') === 'ar' ? 'ايميل المحال اليه' : 'Assignee Email',
            selector: row => row?.assigneeEmail ? row?.assigneeEmail : <span data-tag='allowRowEvents' className="text-gray-600 font-semibold">{t('common.none')}</span>,
            sortable: true,
        },
        {
            id: "createdBy",
            name: localStorage.getItem('lang') === 'ar' ? 'انشات بواسطة' : 'Created By',
            selector: row => row?.createdBy?.firstName + " " + row?.createdBy?.lastName,
            sortable: false,
        },
        {
            id: "createdAt",
            name: localStorage.getItem('lang') === 'ar' ? 'تاريخ الانشاء' : 'Created At',
            selector: row => <div data-tag='allowRowEvents' title={dateFormate(row?.createdAt)}>{dateFormate(row?.createdAt)}</div>,
            sortable: true,
        },
        {
            id: "customerName",
            name: localStorage.getItem('lang') === 'ar' ? 'اسم العميل' : 'Customer Name',
            selector: row => row?.customerName ? row?.customerName : <span data-tag='allowRowEvents' className="font-bold text-gray-600">{t('common.none')}</span>,
            sortable: true,
        },
        {
            id: "customerPhone",
            name: localStorage.getItem('lang') === 'ar' ? 'هاتف العميل' : 'Customer Phone',
            selector: row => <div data-tag='allowRowEvents' title={row?.customerMobileNumber}>{row?.customerMobileNumber ? row?.customerMobileNumber : <span data-tag='allowRowEvents' className="font-bold text-gray-600">{t('common.none')}</span>}</div>,
            sortable: true,
        },
        // {
        //     name: 'Customer Email',
        //     selector: row => <div data-tag='allowRowEvents' title={row?.customerEmail}>{row?.customerEmail ? row?.customerEmail : <span data-tag='allowRowEvents' className="font-bold text-gray-600">{t('common.none')}</span>}</div>,
        //     sortable: false,
        // },
        {
            id: "orderId",
            name: localStorage.getItem('lang') === 'ar' ? 'رقم الطلب' : 'Order ID',
            selector: row => row?.orderId,
            sortable: false,
        },
        {
            id: "restaurantName",
            name: localStorage.getItem('lang') === 'ar' ? 'اسم المطعم' : 'Restaurant Name',
            selector: row => row?.restaurantName ? <div className="flex items-center" title={row?.restaurantName}> <span data-tag='allowRowEvents'>{row?.restaurantName}</span></div> : <span data-tag='allowRowEvents' className="font-bold text-gray-600">{t('common.none')}</span>,
            sortable: true,
        },
        {
            id: "courierName",
            name: localStorage.getItem('lang') === 'ar' ? 'اسم السائق' : 'Courier Name',
            selector: row => <div data-tag='allowRowEvents' title={row?.courierName}>{row?.courierName ? row?.courierName : <span data-tag='allowRowEvents' className="font-bold text-gray-600">{t('common.none')}</span>}</div>,
            sortable: true,
        },
        {
            id: "courierPhone",
            name: localStorage.getItem('lang') === 'ar' ? 'هاتف السائق' : 'Courier Phone',
            selector: row => <div data-tag='allowRowEvents' title={row?.courierMobileNumber}>{row?.courierMobileNumber ? row?.courierMobileNumber : <span data-tag='allowRowEvents' className="font-bold text-gray-600">{t('common.none')}</span>}</div>,
            sortable: true,
        },
        {
            id: "status",
            name: localStorage.getItem('lang') === 'ar' ? 'الحالة' : 'status',
            selector: row => <div data-tag='allowRowEvents' className={` capitalize font-bold px-2 py-1 rounded-md bg-opacity-10 min-w-[100px] text-center ${row?.status === 'OPEN' ? 'text-mainRed bg-mainRed' : row?.status === 'CLOSED' ? 'text-mainColor bg-mainColor' : row?.status === 'RESOLVED' ? 'text-mainGreen bg-mainGreen' : row?.status === 'REOPENED' ? 'text-[#c88256] bg-mainYellow' : ''}`}>
                {
                    row?.status === 'CLOSED' ? t('common.closed') :
                        row?.status === 'OPEN' ? t('common.open') :
                            row?.status === 'RESOLVED' ? t('common.resolved') :
                                row?.status === 'REOPENED' ? t('common.reopened') : null

                }
            </div>,
            sortable: true,
        },
        // {
        //     name: 'Assign',
        //     allowOverflow: true,
        //     button : true,
        //     omit : auth?.role !== 'SUPER_ADMIN',
        //     cell: (row)=><AssignToModal data={row} />

        // },
        {
            id: "actions",
            name: localStorage.getItem('lang') === 'ar' ? 'الاوامر' : 'Actions',
            allowOverflow: true,
            button: true,
            cell: (data) => ActionCell({ data })

        },
    ];


    const data = allTickets?.allTickets?.data

    // console.log(data);

    const handlePageChange = page => {
        setpage(page);
    };
    const handleRowChange = (perPage) => {
        setsize(perPage);
    };

    // useEffect(() => {
    //     // console.log(size)
    //     dispatch(getAllTickets({page,size,col,dir,selectedDate,searchValue}))
    // }, [dispatch, page,size,searchValue])

    const handleSearch = () => {
        dispatch(getAllTickets({ searchValue })).then(() => dispatch(resetPage()))
    }

    const handleReset = () => {
        dispatch(getAllTickets({ page, size })).then(() => dispatch(resetPage()))
        setsearchValue('')
    }

    useEffect(() => {
        //   console.log(searchValue);
    }, [searchValue])


    // functions to handle hide / display table columns

    const [selectedColumns, setSelectedColumns] = useState(['assigneeName', 'createdBy', 'createdAt', 'customerName', 'customerPhone', 'orderId', 'restaurantName', 'courierName', 'courierPhone', 'status', 'actions']);

    const [open, setopen] = useState(false)
    const [visibleColumns, setVisibleColumns] = useState(columns.filter((column) => selectedColumns.includes(column.id)));

    useEffect(() => {
        setSelectedColumns(['assigneeName', 'createdBy', 'createdAt', 'customerName', 'customerPhone', 'orderId', 'restaurantName', 'courierName', 'courierPhone', 'status', 'actions']);
    }, []);

    useEffect(() => {
        setVisibleColumns(columns.filter((column) => selectedColumns.includes(column.id)));
    }, [selectedColumns]);


    const handleOpen = () => {
        setopen(true)
    }
    const handleClose = () => {
        setopen(false)
    }

    const handleVisibleColumnsChange = useCallback((newVisibleColumns) => {
        setVisibleColumns(newVisibleColumns);
    }, []);

    // filter using date range

    const [date, setdate] = useState([
        {
            startDate: null,
            endDate: null,
            key: 'selection'
        }
    ]);

    let fromDate = new Date(date[0]?.startDate)
    let toDate = new Date(date[0]?.endDate)

    const handleDateRangeChange = async (e) => {
        setdate([e.selection])
        let dateRange = e?.selection
        let fromDate = dayjs(dateRange?.startDate).utc().format('YYYY-MM-DDTHH:mm:ss')
        let toDate = dayjs(dateRange?.endDate).endOf('day').utc().format('YYYY-MM-DDTHH:mm:ss')
        let selectedDate = { fromDate: fromDate, toDate: toDate }
        setSelectedDate(selectedDate);

        await dispatch(getAllTickets({ page, size, searchValue, selectedDate })).then(() => {
            dispatch(resetSearch());
            dispatch(resetSort({ sort: true }))
            dispatch(resetPage())
        })
    }

    const { dateOverlay } = useSelector(state => state.overlay)

    const handleButton = () => {
        dispatch(openHandler({ dateShow: !dateOverlay }))
    }


    // handle dropdown sort
    // const [anchorElBy, setAnchorElBy] = useState(null);
    // const openBy = Boolean(anchorElBy);
    // const handleClickBy = (event) => {
    //     setAnchorElBy(event.currentTarget);
    // };
    // const handleCloseBy = () => {
    //     setAnchorElBy(null);
    // };
    // const [anchorElOrder, setAnchorElOrder] = useState(null);
    // const openOrder = Boolean(anchorElOrder);
    // const handleClickOrder = (event) => {
    //     setAnchorElOrder(event.currentTarget);
    // };
    // const handleCloseOrder = () => {
    //     setAnchorElOrder(null);
    // };

    // const [selectedByIndex, setselectedByIndex] = useState(-1)
    // const [selectedOrderIndex, setselectedOrderIndex] = useState(-1)

    // const sortByList = [
    //     {name:'created at'},
    // ]

    // const sortOrderList = [
    //     {name:'Asc'},
    //     {name:'Desc'},
    // ]

    // const handleActiveBy = (item,index)=>{
    //     setselectedByIndex(index)
    //     setcol(item?.name)
    //     setAnchorElBy(null)
    //     // dispatch(getBranches({page,row,col,dir,searchValue}))
    // }
    // const handleActiveOrder = (item,index)=>{
    //     setselectedOrderIndex(index)
    //     setdir(item?.name)
    //     setAnchorElOrder(null)
    // }

    const { handleRemoteSort, icon } = useRemoteSort(getAllTickets, dispatch, page, size, searchValue, null, selectedDate)

    return (
        <>

            <section className={`tickets-control-wrapper relative flex flex-wrap ${localStorage.getItem('lang') === 'ar' ? 'flex-row-reverse' : 'flex-row'} justify-center lg:justify-between items-center mt-5 w-full`}>

                <div className={`tickets-filter-wrapper flex justify-evenly items-center [&>div]:mr-5 ${localStorage.getItem('lang') === 'ar' ? 'flex-row-reverse' : 'flex-row'}`}>
                    <div className='date-wrapper relative'>
                        <ListItemButton className='bg-white text-[#333] font-semibold rounded-md' onClick={handleButton}>
                            <button className='flex items-center text-[#333] font-semibold font-playfair capitalize text-sm'> {date[0]?.startDate && date[0]?.endDate ? new Date(fromDate.getTime() + (fromDate?.getTimezoneOffset() * -60000)).toISOString().slice(0, 10) + " - " + new Date(toDate.getTime() + (toDate?.getTimezoneOffset() * -60000)).toISOString().slice(0, 10) : t('orders.date_title')} <span className='ml-1'><FaAngleDown /></span></button>
                        </ListItemButton>

                        {dateOverlay ?

                            <div className={`absolute top-full z-[9999] bg-white ${localStorage?.getItem('lang') === 'ar' ? 'left-full' : ''}`} dir='ltr'>
                                <DateRangePicker
                                    onChange={handleDateRangeChange}
                                    showSelectionPreview={true}
                                    moveRangeOnFirstSelection={false}
                                    months={1}
                                    ranges={date}
                                    direction="vertical"
                                />
                            </div> : null}

                    </div>

                    {/* <div className='flex gap-x-3 '>
                        <div>
                            <Button
                                id="sort-dropdown"
                                aria-controls={openBy ? 'demo-customized-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={openBy ? 'true' : undefined}
                                variant="contained"
                                disableElevation
                                onClick={handleClickBy}
                                endIcon={<KeyboardArrowDownIcon />}
                                className='bg-[#fff] text-mainColor py-3 text-sm font-[600] capitalize'
                            >
                                {col ? col?.replace(/_/g, " ") : 'Sort By'}
                            </Button>
                            <Menu
                                id="demo-customized-menu"
                                MenuListProps={{
                                'aria-labelledby': 'sort-dropdown',
                                }}
                                anchorEl={anchorElBy}
                                open={openBy}
                                onClose={handleCloseBy}
                                className=' w-full capitalize'
                            >
                                <MenuItem disabled className='text-[#9b96a8] hover:bg-[#d9c8ff42] capitalize'>
                                    Sort By
                                </MenuItem>

                                {sortByList?.map( (item,index)=>(
                                    <MenuItem 
                                        key={index} 
                                        className='text-[#333] font-semibold hover:bg-[#d9c8ff42]'
                                        onClick={()=>handleActiveBy(item,index)}
                                        selected = {index === selectedByIndex}
                                    >
                                        {item?.name?.replace(/_/g, " ")}
                                    </MenuItem>
                                ) )}
                                
                            </Menu>
                        </div>

                        <div>

                            <Button
                                id="sort-dropdown"
                                aria-controls={openOrder ? 'demo-customized-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={openOrder ? 'true' : undefined}
                                variant="contained"
                                disableElevation
                                onClick={handleClickOrder}
                                endIcon={<KeyboardArrowDownIcon />}
                                className='bg-[#fff] text-mainColor py-3 text-sm font-semibold capitalize'
                            >
                                {dir ? dir : 'Sort Order'}
                            </Button>
                            <Menu
                                id="demo-customized-menu"
                                MenuListProps={{
                                'aria-labelledby': 'sort-dropdown',
                                }}
                                anchorEl={anchorElOrder}
                                open={openOrder}
                                onClose={handleCloseOrder}
                                className=' w-full capitalize'
                            >
                                <MenuItem disabled className='text-[#9b96a8] hover:bg-[#d9c8ff42]'>
                                    Sort Order
                                </MenuItem>

                                {sortOrderList?.map( (item,index)=>(
                                    <MenuItem 
                                        key={index} 
                                        className='text-[#333] hover:bg-[#d9c8ff42] font-semibold'
                                        onClick={()=>handleActiveOrder(item,index)}
                                        selected = {index === selectedOrderIndex}
                                    >
                                        {item?.name}
                                    </MenuItem>
                                ) )}
                                
                            </Menu>
                            
                        </div>
                    </div> */}

                </div>

                <div className={`flex items-center mt-3 gap-x-4 ${localStorage.getItem('lang') === 'ar' ? 'flex-row-reverse' : 'flex-row'}`}>
                    <FormControl
                        className="search-form [&>div>input]:py-[.6rem]"
                        sx={{ width: '35ch', backgroundColor: 'white', borderRadius: '6px', '& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': { padding: '10px' }, '& fieldset': { borderColor: 'primary.main', border: 'none' } }} variant="outlined">
                        <OutlinedInput
                            type="search"
                            id="outlined-adornment-search"
                            value={searchValue}
                            placeholder={`${localStorage.getItem('lang') === 'ar' ? 'ابحث في الشكاوى...' : 'Search Tickets...'}`}
                            onChange={(e) => {
                                setsearchValue(e.target.value)
                                dispatch(resetPage())
                                if (!e.target.value) {
                                    handleReset();
                                    dispatch(resetPage())
                                }
                            }}
                            startAdornment={
                                <InputAdornment position="start">
                                    <IconButton
                                        aria-label="search"
                                        onClick={handleSearch}
                                        edge="start"
                                    >
                                        <CiSearch />
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>

                    <div>
                        <ColumnSelection columns={columns} handleOpen={handleOpen} defColumns={selectedColumns} onVisibleColumnsChange={handleVisibleColumnsChange} open={open} close={handleClose} />
                        {/* <Button onClick={handleOpen} className='capitalize text-white bg-mainColor py-2 px-2 pr-2 rounded-md'> <LuMousePointerClick className='w-7 h-7 m-auto' />select columns</Button> */}
                    </div>
                </div>
            </section>

            {/* <section className="w-full mt-4">
                <p className="mb-2 font-semibold capitalize text-mainColor">select columns</p>
                <div className="w-full">
                    <Select
                        sx={{'& fieldset': { borderColor: 'transparent',border:'none' }}}
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        multiple
                        value={selectedColumns}
                        onChange={handleColumnSelection}
                        className="w-full bg-white"
                        renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => (
                                <Chip key={value} label={value} className="bg-[#63bad7] text-white" />
                                ))}
                            </Box>
                            )}
                    >
                        {columns.map((column,index) => (
                            <MenuItem
                                selected = {index === 0 || 1 || 2}
                                key={index}
                                value={column.name}
                            >
                                {column.name}
                            </MenuItem>
                        ))}
                    </Select>
                </div>
            </section> */}

            <section className="datatable-wrapper mt-5 bg-white rounded-md relative">
                {data?.length === 0 ?
                    <div className='p-8'><NoDataEmoji /></div>
                    :
                    <>
                        <DataTable
                            direction={localStorage.getItem('lang') === 'ar' ? 'rtl' : 'ltr'}
                            columns={visibleColumns}
                            data={data}
                            pagination
                            paginationPerPage={size}
                            paginationRowsPerPageOptions={[10, 50, 100]}
                            paginationServer
                            paginationTotalRows={allTickets?.allTickets?.meta?.total}
                            onChangePage={handlePageChange}
                            onChangeRowsPerPage={handleRowChange}
                            customStyles={TableStyles}
                            highlightOnHover
                            onRowClicked={(data) => navigate('details', { state: { data } })}
                            sortServer
                            sortIcon={sort ? <BsDot className="text-[1px] opacity-0" /> : icon}
                            onSort={handleRemoteSort}
                            paginationDefaultPage={page}
                            keyField="id"
                            paginationResetDefaultPage={true}
                            paginationComponentOptions={
                                {
                                    rowsPerPageText: t('common.paginationRowText'),
                                    rangeSeparatorText: t('common.of')
                                }
                            }
                        />
                    </>
                }

                {allTickets?.loading ?
                    <div className='absolute top-0 bottom-0 left-0 right-0 bg-[rgba(0,0,0,.1)] backdrop-blur-sm flex justify-center items-center'>
                        <p className='w-20 h-20 border-4 border-t-0 border-b-0 border-gray-500 rounded-full animate-spin'></p>
                    </div> : null}

            </section>
        </>
    );
}

export default AllTickets;