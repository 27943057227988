import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { errorToast } from "../Toast-Messages/toastMessage";

export const getCouriers = createAsyncThunk(
  "CouriersFunc",
  async (
    { page, size, col, dir, searchValue, orderType, payoutStatus },
    { rejectWithValue, dispatch }
  ) => {
    // console.log(page,size);
    let colCheck;

    if (col) {
      colCheck =
        col === "Joining Date" || col === "تاريخ التسجيل"
          ? "created_at"
          : col === "Updated at" || col === "تاريخ التعديل"
          ? "updated_at"
          : col === "Name" || col === "الاسم"
          ? "full_name"
          : col === "Status" || col === "الحالة"
          ? "is_active"
          : col === "Email" || col === "الايميل"
          ? "email"
          : col === "Average Rating" || col === "متوسط التقييم"
          ? "average_rating"
          : col.replace(/\s+/g, "").toLowerCase();
    }

    const url = !col
      ? `/users/couriers/all?page=${page ? page : 1}&size=${size ? size : 10}${
          orderType ? `&courier_type=${orderType}` : `&courier_type=${"all"}`
        }${
          payoutStatus && payoutStatus !== " "
            ? `&payout_inheritance=${payoutStatus}`
            : ""
        }${searchValue ? `&search=${encodeURIComponent(searchValue)}` : ""}`
      : `/users/couriers/all?page=${page ? page : 1}&size=${size ? size : 10}${
          orderType ? `&courier_type=${orderType}` : `&courier_type=${"all"}`
        }${
          payoutStatus && payoutStatus !== " "
            ? `&payout_inheritance=${payoutStatus}`
            : ""
        }${
          searchValue ? `&search=${encodeURIComponent(searchValue)}` : ""
        }&sortBy=${colCheck}&sortOrder=${dir.toUpperCase()}`;
    try {
      const res = await axios.get(url);
      // console.log(res);
      return res.data;
    } catch (error) {
      // console.log(error.response);
      if (error.response && error.response.data) {
        if (Array?.isArray(error.response.data.message)) {
          error.response.data.message &&
            error.response.data.message?.map((item) =>
              dispatch(errorToast(item))
            );
        } else {
          dispatch(errorToast(error.response.data.message));
        }
        return rejectWithValue(error.response.data.message);
      } else if (navigator.onLine === false) {
        dispatch(errorToast("Check Internet Connection"));
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getCouriersRequests = createAsyncThunk(
  "CouriersRequestsFunc",
  async (
    { page, size, col, dir, searchValue, orderType, payoutStatus },
    { rejectWithValue, dispatch }
  ) => {
    // console.log(page,size);
    let colCheck;

    if (col) {
      // colCheck = col === 'Joining Date' ? 'created_at' : col === 'Updated at' ? 'updated_at' : col === 'Courier Name' ? 'name' : col.replace(/\s+/g, '');
      colCheck =
        col === "Joining Date" || col === "تاريخ التسجيل"
          ? "created_at"
          : col === "Updated at" || col === "تاريخ التعديل"
          ? "updated_at"
          : col === "Name" || col === "الاسم"
          ? "full_name"
          : col === "Status" || col === "الحالة"
          ? "is_active"
          : col === "Email" || col === "الايميل"
          ? "email"
          : col === "Average Rating" || col === "متوسط التقييم"
          ? "average_rating"
          : col.replace(/\s+/g, "").toLowerCase();
    }

    const url = !col
      ? `/users/couriers/requests?page=${page ? page : 1}&size=${
          size ? size : 10
        }${
          orderType ? `&courier_type=${orderType}` : `&courier_type=${"all"}`
        }${
          payoutStatus && payoutStatus !== " "
            ? `&payout_inheritance=${payoutStatus}`
            : ""
        }${searchValue ? `&search=${encodeURIComponent(searchValue)}` : ""}`
      : `/users/couriers/requests?page=${page ? page : 1}&size=${
          size ? size : 10
        }${
          orderType ? `&courier_type=${orderType}` : `&courier_type=${"all"}`
        }${
          payoutStatus && payoutStatus !== " "
            ? `&payout_inheritance=${payoutStatus}`
            : ""
        }${
          searchValue ? `&search=${encodeURIComponent(searchValue)}` : ""
        }&sortBy=${colCheck}&sortOrder=${dir.toUpperCase()}`;
    try {
      const res = await axios.get(url);
      // console.log(res);
      return res.data;
    } catch (error) {
      // console.log(error.response);
      if (error.response && error.response.data) {
        if (Array?.isArray(error.response.data.message)) {
          error.response.data.message &&
            error.response.data.message?.map((item) =>
              dispatch(errorToast(item))
            );
        } else {
          dispatch(errorToast(error.response.data.message));
        }
        return rejectWithValue(error.response.data.message);
      } else if (navigator.onLine === false) {
        dispatch(errorToast("Check Internet Connection"));
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getCouriersRejected = createAsyncThunk(
  "CouriersRejectedFunc",
  async (
    { page, size, col, dir, searchValue, orderType },
    { rejectWithValue, dispatch }
  ) => {
    // console.log(page,size);
    let colCheck;

    if (col) {
      // colCheck = col === 'Joining Date' ? 'created_at' : col === 'Updated at' ? 'updated_at' : col === 'Courier Name' ? 'name' : col.replace(/\s+/g, '');
      colCheck =
        col === "Joining Date" || col === "تاريخ التسجيل"
          ? "created_at"
          : col === "Updated at" || col === "تاريخ التعديل"
          ? "updated_at"
          : col === "Name" || col === "الاسم"
          ? "full_name"
          : col === "Status" || col === "الحالة"
          ? "is_active"
          : col === "Email" || col === "الايميل"
          ? "email"
          : col === "Average Rating" || col === "متوسط التقييم"
          ? "average_rating"
          : col.replace(/\s+/g, "").toLowerCase();
    }

    const url = !col
      ? `/users/couriers/rejected?page=${page ? page : 1}&size=${
          size ? size : 10
        }${
          orderType ? `&courier_type=${orderType}` : `&courier_type=${"all"}`
        }${searchValue ? `&search=${encodeURIComponent(searchValue)}` : ""}`
      : `/users/couriers/rejected?page=${page ? page : 1}&size=${
          size ? size : 10
        }${
          orderType ? `&courier_type=${orderType}` : `&courier_type=${"all"}`
        }${
          searchValue ? `&search=${encodeURIComponent(searchValue)}` : ""
        }&sortBy=${colCheck}&sortOrder=${dir.toUpperCase()}`;
    try {
      const res = await axios.get(url);
      // console.log(res);
      return res.data;
    } catch (error) {
      // console.log(error.response);
      if (error.response && error.response.data) {
        if (Array?.isArray(error.response.data.message)) {
          error.response.data.message &&
            error.response.data.message?.map((item) =>
              dispatch(errorToast(item))
            );
        } else {
          dispatch(errorToast(error.response.data.message));
        }
        return rejectWithValue(error.response.data.message);
      } else if (navigator.onLine === false) {
        dispatch(errorToast("Check Internet Connection"));
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getCouriersDeactivated = createAsyncThunk(
  "CouriersdeactivatedFunc",
  async (
    { page, size, col, dir, searchValue },
    { rejectWithValue, dispatch }
  ) => {
    // console.log(page,size);
    let colCheck;

    if (col) {
      colCheck =
        col === "Joining Date"
          ? "created_at"
          : col === "Updated at"
          ? "updated_at"
          : col === "Courier Name"
          ? "name"
          : col.replace(/\s+/g, "");
    }

    const url = !col
      ? `/users/couriers/deactivated?page=${page ? page : 1}&size=${
          size ? size : 10
        }${searchValue ? `&search=${encodeURIComponent(searchValue)}` : ""}`
      : `/users/couriers/deactivated?page=${page ? page : 1}&size=${
          size ? size : 10
        }${
          searchValue ? `&search=${encodeURIComponent(searchValue)}` : ""
        }&sortBy=${colCheck}&sortOrder=${dir.toUpperCase()}`;
    try {
      const res = await axios.get(url);
      // console.log(res);
      return res.data;
    } catch (error) {
      // console.log(error.response);
      if (error.response && error.response.data) {
        if (Array?.isArray(error.response.data.message)) {
          error.response.data.message &&
            error.response.data.message?.map((item) =>
              dispatch(errorToast(item))
            );
        } else {
          dispatch(errorToast(error.response.data.message));
        }
        return rejectWithValue(error.response.data.message);
      } else if (navigator.onLine === false) {
        dispatch(errorToast("Check Internet Connection"));
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getCouriersActive = createAsyncThunk(
  "CouriersactiveFunc",
  async (
    { page, size, col, dir, searchValue },
    { rejectWithValue, dispatch }
  ) => {
    // console.log(page,size);
    let colCheck;

    if (col) {
      colCheck =
        col === "Joining Date"
          ? "created_at"
          : col === "Updated at"
          ? "updated_at"
          : col === "Courier Name"
          ? "name"
          : col.replace(/\s+/g, "");
    }

    const url = !col
      ? `/users/couriers/active?page=${page ? page : 1}&size=${
          size ? size : 10
        }${searchValue ? `&search=${encodeURIComponent(searchValue)}` : ""}`
      : `/users/couriers/active?page=${page ? page : 1}&size=${
          size ? size : 10
        }${
          searchValue ? `&search=${encodeURIComponent(searchValue)}` : ""
        }&sortBy=${colCheck}&sortOrder=${dir.toUpperCase()}`;
    try {
      const res = await axios.get(url);
      // console.log(res);
      return res.data;
    } catch (error) {
      // console.log(error.response);
      if (error.response && error.response.data) {
        if (Array?.isArray(error.response.data.message)) {
          error.response.data.message &&
            error.response.data.message?.map((item) =>
              dispatch(errorToast(item))
            );
        } else {
          dispatch(errorToast(error.response.data.message));
        }
        return rejectWithValue(error.response.data.message);
      } else if (navigator.onLine === false) {
        dispatch(errorToast("Check Internet Connection"));
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getCourierById = createAsyncThunk(
  "CouriersByIdFunc",
  async (id, { rejectWithValue, dispatch }) => {
    // console.log(page,size);

    try {
      const res = await axios.get(`/users/couriers/${id}`);
      // console.log(res);
      return res.data;
    } catch (error) {
      // console.log(error.response);
      if (error.response && error.response.data) {
        if (Array?.isArray(error.response.data.message)) {
          error.response.data.message &&
            error.response.data.message?.map((item) =>
              dispatch(errorToast(item))
            );
        } else {
          dispatch(errorToast(error.response.data.message));
        }
        return rejectWithValue(error.response.data.message);
      } else if (navigator.onLine === false) {
        dispatch(errorToast("Check Internet Connection"));
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const toggleCourier = createAsyncThunk(
  "toggleCouriersFunc",
  async ({ status, id, values }, { rejectWithValue, dispatch }) => {
    // console.log(status);
    let data = id ? { id: id } : values;
    try {
      const res = await toast.promise(
        axios.put(`/users/couriers/${status}`, data),
        {
          pending: "Loading....",
          success: `Courier ${status}ed Successfully 👌`,
          // error: 'Promise rejected 🤯'
        },
        { toastId: "CourierStatusToast" }
      );
      // console.log(res);
      return res.data;
    } catch (error) {
      // console.log(error.response);
      if (error.response && error.response.data) {
        if (Array?.isArray(error.response.data.message)) {
          error.response.data.message &&
            error.response.data.message?.map((item) =>
              dispatch(errorToast(item))
            );
        } else {
          dispatch(errorToast(error.response.data.message));
        }
        return rejectWithValue(error.response.data.message);
      } else if (navigator.onLine === false) {
        dispatch(errorToast("Check Internet Connection"));
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const rejectCourier = createAsyncThunk(
  "rejectCouriersFunc",
  async (values, { rejectWithValue, dispatch }) => {
    // console.log(status);

    try {
      const res = await toast.promise(
        axios.put(`/users/couriers/${values?.status}`, values),
        {
          pending: "Loading....",
          success: `Courier ${values?.status}ed Successfully 👌`,
          // error: 'Promise rejected 🤯'
        },
        { toastId: "CourierStatusToast" }
      );
      // console.log(res);
      return res.data;
    } catch (error) {
      // console.log(error.response);
      if (error.response && error.response.data) {
        if (Array?.isArray(error.response.data.message)) {
          error.response.data.message &&
            error.response.data.message?.map((item) =>
              dispatch(errorToast(item))
            );
        } else {
          dispatch(errorToast(error.response.data.message));
        }
        return rejectWithValue(error.response.data.message);
      } else if (navigator.onLine === false) {
        dispatch(errorToast("Check Internet Connection"));
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const editPayout = createAsyncThunk(
  "editPayoutsFunc",
  async (values, { rejectWithValue, dispatch }) => {
    // console.log(status);

    try {
      const res = await toast.promise(
        axios.put(`/users/couriers/payout-settings`, values),
        {
          pending: "Loading....",
          success: `Courier Payout Updated Successfully 👌`,
          // error: 'Promise rejected 🤯'
        },
        { toastId: "payoutToast" }
      );
      // console.log(res);
      return res.data;
    } catch (error) {
      // console.log(error.response);
      if (error.response && error.response.data) {
        if (Array?.isArray(error.response.data.message)) {
          error.response.data.message &&
            error.response.data.message?.map((item) =>
              dispatch(errorToast(item))
            );
        } else {
          dispatch(errorToast(error.response.data.message));
        }
        return rejectWithValue(error.response.data.message);
      } else if (navigator.onLine === false) {
        dispatch(errorToast("Check Internet Connection"));
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const initialState = {
  loading: false,
  couriers: [],
  couriersActive: [],
  couriersDeactivated: [],
  couriersRequests: [],
  couriersRejected: [],
  singleCourier: [],
  error: null,
};
export const CouriersSlice = createSlice({
  name: "CouriersSlice-Func",
  initialState,
  reducers: {
    clearCourierState: (state, action) => {
      state.singleCourier = [];
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getCouriers.pending, (state, action) => {
        state.loading = true;
        state.error = "";
      })

      .addCase(getCouriers.fulfilled, (state, action) => {
        state.loading = false;
        state.error = "";
        state.couriers = action.payload;
        // console.log(action.payload);
      })

      .addCase(getCouriers.rejected, (state, action) => {
        state.loading = false;
        state.couriers = "";
        state.error = action.payload;
      })

      .addCase(getCouriersActive.pending, (state, action) => {
        state.loading = true;
        state.error = "";
      })

      .addCase(getCouriersActive.fulfilled, (state, action) => {
        state.loading = false;
        state.error = "";
        state.couriersActive = action.payload;
        // console.log(action.payload);
      })

      .addCase(getCouriersActive.rejected, (state, action) => {
        state.loading = false;
        state.couriersActive = "";
        state.error = action.payload;
      })

      .addCase(getCouriersRejected.pending, (state, action) => {
        state.loading = true;
        state.error = "";
      })

      .addCase(getCouriersRejected.fulfilled, (state, action) => {
        state.loading = false;
        state.error = "";
        state.couriersRejected = action.payload;
        // console.log(action.payload);
      })

      .addCase(getCouriersRejected.rejected, (state, action) => {
        state.loading = false;
        state.couriersRejected = "";
        state.error = action.payload;
      })

      .addCase(getCouriersDeactivated.pending, (state, action) => {
        state.loading = true;
        state.error = "";
      })

      .addCase(getCouriersDeactivated.fulfilled, (state, action) => {
        state.loading = false;
        state.error = "";
        state.couriersDeactivated = action.payload;
        // console.log(action.payload);
      })

      .addCase(getCouriersDeactivated.rejected, (state, action) => {
        state.loading = false;
        state.couriersDeactivated = "";
        state.error = action.payload;
      })

      .addCase(getCouriersRequests.pending, (state, action) => {
        state.loading = true;
        state.error = "";
      })

      .addCase(getCouriersRequests.fulfilled, (state, action) => {
        state.loading = false;
        state.error = "";
        state.couriersRequests = action.payload;
        // console.log(action.payload);
      })

      .addCase(getCouriersRequests.rejected, (state, action) => {
        state.loading = false;
        state.couriersRequests = "";
        state.error = action.payload;
      })

      .addCase(getCourierById.pending, (state, action) => {
        state.loading = true;
        state.error = "";
      })

      .addCase(getCourierById.fulfilled, (state, action) => {
        state.loading = false;
        state.error = "";
        state.singleCourier = action.payload;
        // console.log(action.payload);
      })

      .addCase(getCourierById.rejected, (state, action) => {
        state.loading = false;
        state.singleCourier = "";
        state.error = action.payload;
      })

      .addCase(rejectCourier.pending, (state, action) => {
        state.loading = true;
        state.error = "";
      })

      .addCase(rejectCourier.fulfilled, (state, action) => {
        state.loading = false;
        state.error = "";
        // console.log(action.payload);
      })

      .addCase(rejectCourier.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(editPayout.pending, (state, action) => {
        state.loading = true;
        state.error = "";
      })

      .addCase(editPayout.fulfilled, (state, action) => {
        state.loading = false;
        state.error = "";
        // console.log(action.payload);
      })

      .addCase(editPayout.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { clearCourierState } = CouriersSlice.actions;

export default CouriersSlice.reducer;
